import * as React from 'react';
import { Form, InputOnChangeData } from 'semantic-ui-react';
import { IShipmentEntry } from '../../../../../shared/Api/IEodShipmentsByProduct';

// region Typings
interface IProps {
  disabled: boolean,
  entries: IShipmentEntry[],
  onChange: (entries: IShipmentEntry, amount: string) => void,
}

// endregion

function EntryLabel(props: { entry: IShipmentEntry }) {
  const description = props.entry.shipment.description;
  if (description && description.length > 0) {
    return <label>{props.entry.shipment.description}</label>;
  }
  return <label>{props.entry.shipment.destination}</label>;
}

export function Entries(props: IProps) {
  const entries = props.entries.map(entry => {
    const triggerChange = (e: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
      e.preventDefault();
      props.onChange(entry, data.value);
    };
    
    const handleFocus = (e: React.SyntheticEvent<HTMLInputElement>) => {
      e.currentTarget.select();
    };
    
    return (
      <Form.Field key={entry.shipmentId} disabled={props.disabled}>
        <EntryLabel entry={entry}/>
        <Form.Input size="mini" type="number" onFocus={handleFocus} onChange={triggerChange} value={entry.amount}/>
      </Form.Field>
    );
  });
  
  return <span>{entries}</span>;
}
