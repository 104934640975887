import * as React from 'react';
import { Component, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  CheckboxProps,
  Form,
  Grid,
  Icon,
  Input,
  List,
  Segment
} from 'semantic-ui-react';
import { debounce } from 'throttle-debounce';

import { IInventoryItem } from '../../../../../../../models/IInventoryItem';
import { IApiClient } from '../../../../../../../shared/Api/IApiClient';
import { BottomRight, TopMenu } from '../../../../../../../shared/components';
import { Layout } from '../../../../../../../shared/components/Layout';
import { Padded } from '../../../../../../../shared/components/POS/PosView';
import { IUser } from '../../../../../../../shared/User/IUser';
import { NewInventoryItemModal } from './NewInventoryItemModal';
import { ProductRatios } from './ProductRatios';
import { TagsFilter } from './TagsFilter';
import { TagsLabels } from './TagsLabels';

interface IProps {
  apiClient: IApiClient,
  user: IUser
}

interface IState {
  selectedTag: string,
  tags: string[],
  filteredInventoryItems: IInventoryItem[],
  includeInactive: boolean,
  inventoryItems: IInventoryItem[],
  modalOpen: boolean,
  searchTerms: string
}

export class InventoryItemList extends Component<IProps & RouteComponentProps, IState> {
  public state: IState = {
    filteredInventoryItems: [],
    includeInactive: false,
    inventoryItems: [],
    modalOpen: false,
    searchTerms: '',
    selectedTag: '',
    tags: [],
  };

  private readonly applyFilterDebounced: (tag: string, searchTerms: string) => void;

  constructor(props: IProps & RouteComponentProps) {
    super(props);
    this.applyFilterDebounced = debounce(250, this.applyFilter);
  }

  public async componentDidMount(): Promise<void> {
    const {apiClient} = this.props;
    Promise
      .all([
        apiClient.inventoryItems.queryInventoryItemsAsync(),
        apiClient.inventoryItems.getTagsAsync()])
      .then(([inventoryItems, tags]) => {
        this.setState({
          filteredInventoryItems: inventoryItems,
          inventoryItems,
          tags
        });
      });
  }

  public render() {
    return <Layout nav={this.renderMenu(this.props)} body={this.renderBody(this.props)}/>;
  }

  private renderMenu(props: IProps & RouteComponentProps) {
    return <TopMenu {...props}>Gestion de l'inventaire</TopMenu>
  }

  private renderBody(props: IProps & RouteComponentProps) {
    const inventoryItems = this.state.filteredInventoryItems.map(item =>
      <List.Item key={item.id}>
        <List.Content floated="right">
          <Button as={Link} to={`/inventory-items/${item.id}`} size="mini">Voir</Button>
        </List.Content>
        <List.Content>
          <List.Header>{item.name} <TagsLabels tags={item.tags}/></List.Header>
          <List.Description>
            <small>
              <Icon name="food"/> <ProductRatios productRatios={item.productRatios}/>
            </small>
          </List.Description>
        </List.Content>
      </List.Item>
    );

    return <Padded>
      <Grid columns={2} container>
        <Grid.Column width={4}>
          <Segment>
            <TagsFilter
              tags={this.state.tags}
              selectedTag={this.state.selectedTag}
              onChange={this.handleSelectedTag}/>
          </Segment>
        </Grid.Column>
        <Grid.Column width={12}>
          <Segment>
            <Form>
              <Form.Field>
                <Input icon="search" onChange={this.handleSearch} placeholder="Rechercher..."/>
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Inclure les inactifs"
                  checked={this.state.includeInactive}
                  onChange={this.handleIncludeInactiveChange}/>
              </Form.Field>
            </Form>
            <List id="inventory-items" verticalAlign="middle" divided relaxed>
              {inventoryItems}
            </List>
          </Segment>
        </Grid.Column>
      </Grid>
      <BottomRight>
        <Button onClick={this.handleNewInventoryItem} circular color="pink" icon="plus"/>
        <NewInventoryItemModal
          open={this.state.modalOpen}
          apiClient={this.props.apiClient}
          onCreated={this.handleNewInventoryItemCreated}
          onCancel={this.handleNewInventoryItemCancel}
          tags={this.state.tags}/>
      </BottomRight>
    </Padded>
  }

  private handleSearch = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    this.applyFilterDebounced(this.state.selectedTag, target.value)
  };

  private handleSelectedTag = (selectedTag: string) => {
    this.applyFilterDebounced(selectedTag, this.state.searchTerms);
  };

  private applyFilter = (selectedTag: string, searchTerms: string) => {
    const filteredInventoryItems = this.filterInventoryItems(this.state.inventoryItems, selectedTag, searchTerms);

    this.setState({
      filteredInventoryItems,
      searchTerms,
      selectedTag
    });
  }

  private filterInventoryItems = (inventoryItems: IInventoryItem[], tag: string, searchTerms: string): IInventoryItem[] => {
    let filteredItems = [...inventoryItems];

    if (tag !== '') {
      filteredItems = filteredItems.filter((item) => {
        return item.tags.indexOf(tag) !== -1
      })
    }

    if (searchTerms !== '') {
      filteredItems = filteredItems.filter((item) => {
        let itemName = item.name.toLowerCase();
        if (item.productRatios.length > 0) {
          itemName += item.productRatios[0].productSku.toLowerCase()
        }
        return itemName.indexOf(
          searchTerms.toLowerCase()) !== -1
      })
    }

    return filteredItems;
  };

  private handleIncludeInactiveChange = async (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const includeInactive = data.checked as boolean;
    const {apiClient} = this.props;
    const inventoryItems = await apiClient.inventoryItems.queryInventoryItemsAsync(includeInactive);
    const {selectedTag, searchTerms} = this.state;
    const filteredInventoryItems = this.filterInventoryItems(inventoryItems, selectedTag, searchTerms)

    this.setState({
      filteredInventoryItems,
      includeInactive,
      inventoryItems,
    });
  };

  private handleNewInventoryItem = () => {
    this.setState({modalOpen: true});
  };

  private handleNewInventoryItemCancel = () => {
    this.setState({modalOpen: false});
  };

  private handleNewInventoryItemCreated = (createdInventoryItem: IInventoryItem) => {
    this.props.history.push(`/inventory-items/${createdInventoryItem.id}`);
  }
}
