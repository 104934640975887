import * as React from 'react';
import {Label} from 'semantic-ui-react';

export interface IProps {
  tags: string[];
}

export function TagsLabels(props: IProps) {
  const tags = props.tags.map((t: string) => <Label key={t} size="mini" tag>{t}</Label>)

  return <span>{tags}</span>
}