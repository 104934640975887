import * as React from 'react';
import { Dimmer, Header, Icon, List, Loader, Segment } from 'semantic-ui-react';
import { IUser } from '../../../../../shared/User/IUser';
import { IShipment } from '../shared/models/IShipment';
import { EndofdayShipment } from './EndofdayShipment';
import { IncomingShipment } from './IncomingShipment';
import { OutgoingShipment } from './OutgoingShipment';

interface IShipmentsListProps {
  shipments: IShipment[],
  user: IUser,
  loading?: boolean,
}

export function ListOfShipments(props: IShipmentsListProps) {
  const shipmentItems = props.shipments.map(s => <ShipmentItem
    key={s.uuid}
    user={props.user}
    shipment={s}
  />);

  const header = <Header as="h5" attached="top">Confirmés</Header>;

  if (props.loading) {
    return (
      <div>
        {header}
        <Segment attached style={{minHeight: '100px'}}>
          <Dimmer active inverted>
            <Loader>Chargement des envois & livraisons confirmés</Loader>
          </Dimmer>
        </Segment>
      </div>
    );
  }

  if (!props.loading && props.shipments.length === 0) {
    return (
      <div>
        {header}
        <Segment attached>
          <Header icon style={{display: 'block'}}>
            <Icon name="sticker mule" style={{fontSize: '2em'}}/>
            <Header.Subheader>
              Pas de d'envois ou livraison confirmé
            </Header.Subheader>
          </Header>
        </Segment>
      </div>
    );
  }

  return (
    <div id="shipments">
      {header}
      <Segment attached>
        <List divided relaxed>{shipmentItems}</List>
      </Segment>
    </div>
  );
}

export function ShipmentItem({shipment, user}: { shipment: IShipment, user: IUser }) {
  if(shipment.isEndofday){
    return <EndofdayShipment shipment={shipment}/>
  }

  if (user.workingFrom === shipment.origin) {
    return <OutgoingShipment user={user} shipment={shipment}/>
  }

  if (user.workingFrom === shipment.destination) {
    return <IncomingShipment user={user} shipment={shipment}/>
  }

  return <span/>;
}


