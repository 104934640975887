import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Grid, Header, Segment } from 'semantic-ui-react';
import { IApiClient } from '../../../../../shared/Api/IApiClient';
import { FullScreen, TopMenu } from '../../../../../shared/components';
import { Padded } from '../../../../../shared/components/POS/PosView';
import { ICurrentUserContext } from '../../../../../shared/ICurrentUserContext';
import { Roles } from '../../../../../shared/Roles';
import { ImpersonateGuard } from '../../Shipments/components/ImpersonateGuard';
import { InventoryErrorsAlert } from './InventoryErrorsAlert';

// region Typings
interface IProps extends RouteComponentProps {
  userContext: ICurrentUserContext;
  apiClient: IApiClient;
}

// endregion
export function Dashboard(props: IProps) {
  const isAdmin = props.userContext.user.hasRole(Roles.StoreManager);
  return (
    <ImpersonateGuard userContext={props.userContext}>
      <FullScreen>
        <TopMenu {...props}>Dashboard</TopMenu>
        <Padded>

          <Grid centered columns={2}>
            <Grid.Column>
              <Grid>
                <Grid.Column width={4}>
                  <Segment>
                    <Header as="h5">Documents de stock</Header>
                    <Link to="/shipments">Voir</Link>
                  </Segment>
                </Grid.Column>

                {isAdmin && (
                <Grid.Column width={4}>
                  <Segment>
                    <Header as="h5">Gestion de l'inventaire</Header>
                    <Link to="/inventory-items">Voir</Link>
                  </Segment>
                </Grid.Column>
                )}

                {isAdmin && (
                  <Grid.Column width={4}>
                    <Segment>
                      <Header as="h5">Clôturer la session de stock</Header>
                      <Link to="/inventory-sessions">Voir</Link>
                    </Segment>
                  </Grid.Column>
                )}

                <InventoryErrorsAlert apiClient={props.apiClient} force={isAdmin}>
                  <Grid.Column width={4}>
                    <Segment>
                      <Header as="h5">Vérifier les erreurs de stock</Header>
                      <Link to="/inventory-errors">Voir</Link>
                    </Segment>
                  </Grid.Column>
                </InventoryErrorsAlert>

                <Grid.Column width={4}>
                  <Segment>
                    <Header as="h5">Marchandises</Header>
                    <Link to="/marchandises">Consulter les commandes</Link>
                  </Segment>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Padded>
      </FullScreen>
    </ImpersonateGuard>
  );
}
