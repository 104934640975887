import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApiContext } from '../../../../shared/Api/ApiContext';
import { InventorySessions } from './components/InventorySessions';

export function InventorySessionsRoute(props: RouteComponentProps) {
  return (
    <ApiContext.Consumer>
      {(client) => <InventorySessions {...props} client={client}/>}
    </ApiContext.Consumer>
  );
}