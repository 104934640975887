import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { Flex } from '../Flex';
import { IReceiptItem } from './IReceiptItem';

type OnDelete = (id: string) => void;

interface IProps {
  item: IReceiptItem;
  onDelete: OnDelete | undefined;
  disabled?: boolean;
}

export function OverviewItem(props: IProps) {
  const {item, onDelete} = props;

  const showDelete = typeof props.onDelete === 'function';

  return (
    <Flex.Center style={{marginBottom: '0.5rem'}} id={'overviewItem-' + props.item.itemName}>
      <div style={{flexGrow: 1}}>{item.itemName} </div>
      <div>
        {item.amount} {showDelete && (
        <Button
          size="mini"
          icon="trash alternate outline"
          basic
          disabled={props.disabled}
          onClick={(onDelete as OnDelete).bind(null, item.itemId)}
        />
      )}
      </div>
    </Flex.Center>
  );
}
