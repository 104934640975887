import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { RouteComponentProps } from "react-router";
import {
  Dimmer,
  Grid,
  Header,
  Loader,
  Table,
  Transition
} from "semantic-ui-react";
import { IApiClient } from "../../../../../shared/Api/IApiClient";
import { MarchandisesResponse } from "../../../../../shared/Api/IMarchandisesRepository";
import { TopMenu } from "../../../../../shared/components";
import { Layout } from "../../../../../shared/components/Layout";
import { Padded } from "../../../../../shared/components/POS/PosView";
import { ICurrentUserContext } from "../../../../../shared/ICurrentUserContext";
import { ImpersonateGuard } from "../../Shipments/components/ImpersonateGuard";

interface IProps {
  apiClient: IApiClient;
  userContext: ICurrentUserContext;
}

function formatDate(date: Date, format: string, locale: string) {
  return date.toDateString();
}

function parseDate() {
  return;
}

function disabledDays(date: Date) {
  // disable sunday, saturday and future
  return !([0, 6].indexOf(date.getDay()) < 0) || date > new Date();
}
const FORMAT = 'MM/dd/yyyy';
export class Marchandises extends React.Component<
  IProps & RouteComponentProps
> {
  public state = {
    items: [],
    loading: true,
    selectedDay: new Date()
  };

  public componentDidMount(): void {
    this.getItems();
  }

  public render() {
    return (
      <ImpersonateGuard userContext={this.props.userContext}>
        <Layout nav={this.renderNav()} body={this.renderBody()} />
      </ImpersonateGuard>
    );
  }

  private renderBody = () => {
    const items = this.state.items.map((i, idx) => (
      <Table.Row key={idx}>
        <Table.Cell>{i}</Table.Cell>
      </Table.Row>
    ));

    return (
      <Padded>
        <Grid centered columns={2}>
          <Grid.Column style={{ minHeight: "400px" }}>
            <Transition visible={this.state.loading}>
              <Dimmer active={this.state.loading} inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Transition>
            <Transition visible={!this.state.loading}>
              <div>
                <Header block>
                  <DayPickerInput
                    onDayChange={this.handleDateChanged}
                    value={this.state.selectedDay}
                    format={FORMAT}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{ disabledDays }}
                  />
                </Header>
                <br />
                <Table>
                  <Table.Body>{items}</Table.Body>
                </Table>
              </div>
            </Transition>
          </Grid.Column>
        </Grid>
      </Padded>
    );
  };

  private renderNav = () => {
    return (
      <TopMenu
        history={this.props.history}
        location={this.props.location}
        match={this.props.match}
      >
        Marchandises: stock Nat
      </TopMenu>
    );
  };

  private getItems = () => {
    return this.props.apiClient.marchandises
      .get(this.state.selectedDay)
      .then((i: MarchandisesResponse) => {
        const items = i.results.map(r => r.name);
        this.setState({ items, loading: false });
      });
  };

  private handleDateChanged = (day: Date) => {
    this.setState({ loading: true, selectedDay: day }, this.getItems);
  };
}
