import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { ApiClientBuilder } from '../../shared/Api/ApiClientBuilder';
import { ApiContext } from '../../shared/Api/ApiContext';
import Auth from '../../shared/Auth/Auth';
import { AuthContext } from '../../shared/Auth/AuthContext';
import { CurrentUserContext } from '../../shared/CurrentUserContext';
import { ImpersonatedUser } from '../../shared/User/ImpersonatedUser';
import { DashboardRoute } from './screens/Dashboard';
import { InventoryErrorsRoute } from './screens/InventoryErrors';
import { InventoryItemsRoute } from './screens/InventoryItems';
import { InventorySessionsRoute } from './screens/InventorySessions';
import { MarchandisesRoute } from './screens/Marchandises';
import { ShipmentsRoute } from './screens/Shipments';

// region Typings
export interface IAppProps {
  auth: Auth
}

// endregion
class App extends React.Component<IAppProps & RouteComponentProps> {
  public state = {
    currentUser: this.props.auth.getCurrentUser()
  };

  public render() {
    const currentUser = this.state.currentUser;
    const currentUserProvidedValue = {
      setWorkingFrom: this.setWorkingFrom,
      user: currentUser,
    };
    const accessToken = localStorage.getItem('access_token');

    if (!this.props.auth.isAuthenticated()) {
      return <Redirect to="/login"/>;
    }

    const apiClient = new ApiClientBuilder()
      .addToken(accessToken || '')
      .addUnauthorizedInterceptor(this.props.auth.logout)
      .addLocation(currentUser.workingFrom)
      .addUser(currentUser)
      .build();

    return (
      <AuthContext.Provider value={this.props.auth}>
        <CurrentUserContext.Provider value={currentUserProvidedValue}>
          <ApiContext.Provider value={apiClient}>
            <Switch>
              {/* DASHBOARD */}
              <Route path="/dashboard" component={DashboardRoute}/>
              {/* INVENTORY ERRORS */}
              <Route path="/inventory-errors" component={InventoryErrorsRoute}/>
              {/* INVENTORY MANAGEMENT APP */}
              <Route path="/shipments" component={ShipmentsRoute}/>
              {/* INVENTORY ITEMS MANAGEMENT APP */}
              <Route path="/inventory-items" component={InventoryItemsRoute}/>
              {/* INVENTORY SESSIONS */}
              <Route path="/inventory-sessions" component={InventorySessionsRoute}/>
              {/* MARCHANDISES */}
              <Route path="/marchandises" component={MarchandisesRoute}/>
              {/* DEFAULT: redirect to dashboard */}
              <Route path="/" render={(props: RouteComponentProps) => <Redirect to="/dashboard"/>}/>
            </Switch>
          </ApiContext.Provider>
        </CurrentUserContext.Provider>
      </AuthContext.Provider>
    );
  }

  private setWorkingFrom = (v: string) => {
    localStorage.setItem('workingfrom', v);
    const currentUser = new ImpersonatedUser(this.state.currentUser, v);
    this.setState({currentUser});
  };
}

export default App;
