import * as React from 'react';
import {Component} from 'react';
import {Button, Modal} from 'semantic-ui-react';

import {IInventoryItem, InventoryItem} from '../../../../../../../models/IInventoryItem';
import {IApiClient} from '../../../../../../../shared/Api/IApiClient';
import {InventoryItemForm} from '../../shared/InventoryItemForm';

interface IProps {
  open: boolean;
  apiClient: IApiClient;
  onCreated: (inventoryItem: IInventoryItem) => void;
  onCancel: () => void;
  tags: string[];
}

interface IState {
  inventoryItem: IInventoryItem;
}

export class NewInventoryItemModal extends Component<IProps, IState> {
  public state: IState = {
    inventoryItem: new InventoryItem(),
  }

  public render() {
    const modalOpen = this.props.open;
    const inventoryItem = this.state.inventoryItem;
    const tags = this.props.tags;

    return (
        <Modal
            open={modalOpen}
            centered={false}>
          <Modal.Header>Ajouter un nouvel article</Modal.Header>
          <Modal.Content>
            <InventoryItemForm
                inventoryItem={inventoryItem}
                tags={tags}
                onChange={this.handleFormChange}/>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCancel}>Annuler</Button>
            <Button primary onClick={this.handleSave}>Enregistrer</Button>
          </Modal.Actions>
        </Modal>
    );
  }

  private handleFormChange = (inventoryItem: IInventoryItem) => {
    this.setState({inventoryItem});
  }

  private handleSave = async () => {
    const {apiClient} = this.props;
    const createdItem = await apiClient.inventoryItems.saveInventoryItemAsync(this.state.inventoryItem);
    this.props.onCreated(createdItem);
  }

  private handleCancel = () => {
    this.setState({inventoryItem: new InventoryItem()}, this.props.onCancel);
  }
}