export interface IProductDropDown {
  name: string;
  sku: string
}

export interface IProductRatio {
  id: number;
  productSku: string,
  productName: string,
  ratio: number
}

export class ProductRatio implements IProductRatio {
  public id: number = 0;
  public productName: string = '';
  public productSku: string = '';
  public ratio: number = 1;
}