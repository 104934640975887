import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApiContext } from '../../../../../../../shared/Api/ApiContext';
import { CurrentUserContext } from '../../../../../../../shared/CurrentUserContext';
import { Shipment } from './Shipment';

export function InjectShipmentDependencies(props: RouteComponentProps) {
  return (
    <CurrentUserContext.Consumer>
      {userContext => (
        <ApiContext.Consumer>
          {(client) => <Shipment {...props} apiClient={client} userContext={userContext}/>}
        </ApiContext.Consumer>
      )}
    </CurrentUserContext.Consumer>

  );
}