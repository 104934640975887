import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, List } from 'semantic-ui-react';
import { IUser } from '../../../../../shared/User/IUser';
import { IShipment } from '../shared/models/IShipment';

  export function OutgoingShipment({shipment, user}: { shipment: IShipment, user: IUser }) {
  return <List.Item key={shipment.uuid}>
    <List.Content floated="right">
      <Button as={Link} to={`/shipments/${shipment.uuid}`} size="mini">
        {shipment.isReadOnly ? 'Voir' : 'Envoyer'}
      </Button>
    </List.Content>
    <List.Icon name="sign out"/>
    <List.Content>
      <List.Header>{shipment.destination}</List.Header>
      <List.Description>
        <small>
          transfert vers {shipment.destination}
        </small>
      </List.Description>
    </List.Content>
  </List.Item>;
}
