import 'noty/lib/noty.css';
import 'noty/lib/themes/semanticui.css';
import * as ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import { makeRoutes } from './config/routes';
import './index.css';
import Auth from './shared/Auth/Auth';

const routes = makeRoutes(new Auth());

ReactDOM.render(
  routes,
  document.getElementById('root') as HTMLElement
);

// registerServiceWorker();
