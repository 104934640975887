import { AxiosInstance } from 'axios';
import { EodDocumentType } from '../../screens/App/screens/Shipments/shared/models/EndofdayType';
import { IShipment, ShipmentStatuses } from '../../screens/App/screens/Shipments/shared/models/IShipment';
import { IShipmentJson } from '../../screens/App/screens/Shipments/shared/models/IShipmentJson';
import { ShipmentBase } from '../../screens/App/screens/Shipments/shared/models/ShipmentBase';
import { INewEndofdayShipmentOptions } from './INewEndofdayShipmentOptions';
import { IShipmentsRepository } from './IShipmentsRepository';
import { PulpWarehouse } from './PulpWarehouse';

export class ShipmentsRepository implements IShipmentsRepository {
  set location(value: PulpWarehouse) {
    this._location = value;
  }

  private _location: PulpWarehouse | undefined;

  constructor(private _http: AxiosInstance) {
  }

  public async closeShipment(shipment: IShipment) {
    let url: string;
    let response: any;

    if (shipment.nextStatus === ShipmentStatuses.Transit) {
      url = `v1.0/locations/${this._location}/transit`;
      response = await this._http.post(url, {uuid: shipment.uuid});
      return response.data as IShipment;
    }

    if (shipment.nextStatus === ShipmentStatuses.Closed) {
      url = `v1.0/locations/${this._location}/deliveries/${shipment.uuid}`;
      response = await this._http.put(url, {uuid: shipment.uuid});
      return response.data as IShipment;
    }

    return Promise.reject('Next Status not recognised: ' + shipment.nextStatus);
  }

  public async deleteShipment(shipment: IShipment) {
    const url = `v1.0/locations/${this._location}/shipments/${shipment.uuid}`;
    await this._http.delete(url);
  }

  public async createEndofdayShipmentAsync(type: EodDocumentType): Promise<IShipment> {
    const url = `v1.0/locations/${this._location}/eod-documents`;

    let options: INewEndofdayShipmentOptions;

    if (type === EodDocumentType.Inventory) {
      options = {type, counterparty: this._location as PulpWarehouse};
    } else if (type === EodDocumentType.Transfert) {
      options = {type, counterparty: PulpWarehouse.Atelier};
    } else if (type === EodDocumentType.Trash) {
      options = {type, counterparty: PulpWarehouse.Trash};
    } else {
      throw new Error('Invalid EodDocumentType');
    }

    const response = await this._http.post(url, options);

    return response.data as IShipment;
  }

  public async createShipmentAsync(origin: PulpWarehouse, destination: PulpWarehouse): Promise<IShipment> {
    const data = {counterparty: destination, type: 'outbound'};
    const response = await this._http.post(`v1.0/locations/${origin}/shipments`, data);
    return ShipmentBase.createFromJson(response.data);
  }

  public async getShipmentAsync(uuid: string): Promise<IShipment> {
    const response = await this._http.get(`v1.0/locations/${this._location}/shipments/${uuid}`);
    return ShipmentBase.createFromJson(response.data);
  }

  public async getShipmentsAsync(): Promise<{ shipments: IShipment[], arrivals: IShipment[] }> {
    const response = await this._http.get(`v1.0/locations/${this._location}/shipments`);
    const data = response.data as { shipments: IShipmentJson[], arrivals: IShipmentJson[] };
    return {
      arrivals: data.arrivals.map(i => ShipmentBase.createFromJson(i)),
      shipments: data.shipments.map(i => ShipmentBase.createFromJson(i)),
    };
  }

  public async receiveFromAtelier(): Promise<IShipment> {
    const url = `v1.0/locations/${this._location}/deliveries-from-atelier`;
    const response = await this._http.post(url);
    return response.data as IShipment;
  }

  public async receiveShipment(uuid: string): Promise<IShipment> {
    const url = `v1.0/locations/${this._location}/deliveries`;
    const response = await this._http.post(url, {uuid});
    return response.data as IShipment;
  }

  public async saveShipmentItemAsync(shipmentUuid: string, productId: number, amount: number): Promise<IShipment> {
    const url = `v1.0/locations/${this._location}/shipments/${shipmentUuid}/items`;
    const response = await this._http.post(url, {amount, productId});
    return response.data as IShipment;
  }

  public async scheduleShipment(shipment: IShipment, scheduledFor: Date): Promise<IShipment> {
    const url = `v1.0/locations/${this._location}/delayed-shipments`;
    const response = await this._http.post(url, {scheduledFor: scheduledFor.toDateString(), uuid: shipment.uuid});
    return response.data as IShipment;
  }

}
