import { SyntheticEvent } from 'react';
import * as React from 'react';
import { Button, Form, Modal, Radio } from 'semantic-ui-react';
import { EodDocumentType } from '../shared/models/EndofdayType';

// region Typings

interface IProps {
  open: boolean;
  onClose: () => void;
  onCreate: (type: EodDocumentType) => void;
}

interface IState {
  eodDocumentType: EodDocumentType;
}

// endregion

export class NewEndofdayModal extends React.Component<IProps, IState> {
  public state = {
    eodDocumentType: EodDocumentType.Inventory
  };

  public render() {
    const value = this.state.eodDocumentType;
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <Modal.Header>Créer un document de fin de journée</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Group inline>
                <Form.Field
                  control={Radio}
                  label="Inventaire"
                  value={EodDocumentType.Inventory}
                  checked={value === EodDocumentType.Inventory}
                  onChange={this.handleChange}
                />
                <Form.Field
                  control={Radio}
                  label="Mère Thérèsa"
                  value={EodDocumentType.Trash}
                  checked={value === EodDocumentType.Trash}
                  onChange={this.handleChange}
                />
                <Form.Field
                  control={Radio}
                  label="Transfert vers l'Atelier"
                  value={EodDocumentType.Transfert}
                  checked={value === EodDocumentType.Transfert}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClick}>Go</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  private handleClick = () => {
    this.props.onCreate(this.state.eodDocumentType);
  }

  private handleChange = (event: SyntheticEvent<HTMLInputElement>, data: { value: EodDocumentType }) => {
    this.setState({eodDocumentType: data.value});
  }
}