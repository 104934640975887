import { AxiosInstance } from "axios";
import {
  IMarchandisesRepository,
  MarchandisesResponse
} from "./IMarchandisesRepository";
import { PulpWarehouse } from "./PulpWarehouse";

export class MarchandisesRepository implements IMarchandisesRepository {
  private _location: PulpWarehouse | undefined;

  set location(value: PulpWarehouse) {
    this._location = value;
  }

  constructor(private _http: AxiosInstance) {}

  public async get(date: Date): Promise<MarchandisesResponse> {
    const workday = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const response = await this._http.get(
      `v1.0/${this._location}/stock-nat?workday=${workday}`
    );
    return response.data as MarchandisesResponse;
  }
}
