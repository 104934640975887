import { Auth0UserProfile } from 'auth0-js';
import { PulpWarehouse } from '../Api/PulpWarehouse';
import { Roles } from '../Roles';
import { IUser } from './IUser';

export abstract class ALoggedInUser implements IUser {
  public static ROLE_CLAIM = 'https://hq.pulp.be/roles';

  get name(): string {
    return this.userProfile.given_name || this.userProfile.name;
  }

  get profile(): Auth0UserProfile {
    return this.userProfile;
  }

  public abstract get workingFrom(): PulpWarehouse;

  constructor(protected  userProfile: Auth0UserProfile) {
  }

  public abstract canCreateShipmentFrom(): PulpWarehouse[];

  public abstract getLocation(): PulpWarehouse | undefined ;

  public getRoles(): string[] {
    return this.userProfile[ALoggedInUser.ROLE_CLAIM] || [];
  }

  public hasRole(role: Roles): boolean {
    return -1 !== this.getRoles().indexOf(role);
  }

  public is(name: string): boolean {
    return new RegExp(PulpWarehouse[name], 'i').test(this.userProfile.name);
  }

  public abstract shouldImpersonate(): boolean;
}