import { PulpWarehouse } from '../Api/PulpWarehouse';
import { Roles } from '../Roles';
import { ALoggedInUser } from './ALoggedInUser';
import { IUser } from './IUser';

/**
 * Represents a IUser that can act on behalve of another.
 * Store manager are mobile and should be able to act as if they are a store.
 */
export class ImpersonatedUser extends ALoggedInUser {
  private _workingFrom: string;

  public get workingFrom():PulpWarehouse {
    return PulpWarehouse [ this._workingFrom ];
  }

  constructor(loggedInUser: IUser, workingFrom: string) {
    super(loggedInUser.profile);
    this._workingFrom = workingFrom;
  }

  public shouldImpersonate(): boolean {
    return !Boolean(this._workingFrom);
  }

  public canCreateShipmentFrom(): PulpWarehouse[] {
    if (this.hasRole(Roles.StoreManager)) {
      return Object.keys(PulpWarehouse).map(l => PulpWarehouse[l]);
    } else {
      return [];
    }
  }

  public getLocation(): PulpWarehouse | undefined {
    return this.workingFrom;
  }
}