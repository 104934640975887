import * as React from 'react';
import { Component } from 'react';
import { Accordion, Form, Grid, Icon } from 'semantic-ui-react';
import { PreviousSessionProps, PreviousSessionState } from './PreviousSession';

export class PreviousSession extends Component<PreviousSessionProps, PreviousSessionState> {
  public state = {
    diff: 0,
    expand: false,
    summary: {
      delivered: 0,
      endofday: 0,
      initial: 0,
      sales: 0,
      shipped: 0,
      transfertsAtelier: 0,
      trash: 0,
      virtual: 0,
    },
  };
  
  public async componentDidMount(): Promise<void> {
    if (this.props.item) {
      try {
        const summary = await this.props.api.inventorySessions.getSummary(this.props.sessionId, this.props.item.id);
        this.setState({summary: summary.summary, diff: summary.diff});
      } catch (e) {
        console.warn(e.message);
      }
    }
  }
  
  public render() {
    return <Accordion>
      <Accordion.Title active={this.state.expand} onClick={this.toggleExpand}>
        <Icon name="dropdown"/>
        Voir le détail de la session précédente: {this.state.diff}</Accordion.Title>
      <Accordion.Content active={this.state.expand}>
        <Form>
          <Grid>
            <Grid.Column width={8}>
              <Form.Field>
                <label>Trash</label>
                <input type="number" className="mini" readOnly value={this.state.summary.trash}/>
              </Form.Field>
              <Form.Field>
                <label>Atelier</label>
                <input type="number" className="mini" readOnly value={this.state.summary.transfertsAtelier}/>
              </Form.Field>
              <Form.Field>
                <label>Inventaire</label>
                <input type="number" className="mini" readOnly value={this.state.summary.endofday}/>
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form.Field>
                <label>Stock Initial</label>
                <input type="number" className="mini" readOnly value={this.state.summary.initial}/>
              </Form.Field>
              <Form.Field>
                <label>Livraisons</label>
                <input type="number" className="mini" readOnly value={this.state.summary.delivered}/>
              </Form.Field>
              <Form.Field>
                <label>Envois</label>
                <input type="number" className="mini" readOnly value={this.state.summary.shipped}/>
              </Form.Field>
              <Form.Field>
                <label>Ventes</label>
                <input type="number" className="mini" readOnly value={this.state.summary.sales}/>
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form></Accordion.Content></Accordion>;
  }
  
  
  private toggleExpand = () => {
    this.setState({expand: !this.state.expand});
  };
}
