import * as React from 'react';
import { SyntheticEvent } from 'react';
import { DropdownProps, Select } from 'semantic-ui-react';
import { PulpWarehouse } from '../../../../../shared/Api/PulpWarehouse';
import { IUser } from '../../../../../shared/User/IUser';

interface IOriginSelectorProps {
  currentUser: IUser,
  selected: string,
  options: Array<{ text: string, value: string }>,
  onSelected: (value: PulpWarehouse) => void
}

export function OriginSelector(props: IOriginSelectorProps) {
  const {onSelected, options} = props;

  const onChange = (e: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    onSelected(PulpWarehouse[data.value as string]);
  };

  return (options.length === 1)
    ? <Select options={options} value={props.selected} disabled/>
    : <Select required
              onChange={onChange}
              value={props.selected}
              options={options}
              placeholder="Sélectionne une origine"/>;
}

interface IDestinationSelectorProps {
  currentUser: IUser,
  onSelected: (value: PulpWarehouse) => void,
  exclude?: PulpWarehouse
}

export function DestinationSelector(props: IDestinationSelectorProps) {
  const {currentUser, onSelected} = props;

  const allLocationsExceptThisOne = Object.keys(PulpWarehouse)
    .filter(location => !currentUser.is(location))
    .filter(location => location !== props.exclude)
    .map(location => (
      {text: PulpWarehouse[location], value: PulpWarehouse[location]}
    ));

  const onChange = (e: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    onSelected(PulpWarehouse[data.value as string]);
  };

  return <Select onChange={onChange} options={allLocationsExceptThisOne} placeholder="Sélectionne une destination"/>;
}
