export enum PulpWarehouse {
  Archi   = 'Archi',
  Atelier = 'Atelier',
  Impé    = 'Impé',
  Loi     = 'Loi',
  Lux     = 'Lux',
  Madou   = 'Madou',
  Rogier  = 'Rogier',
  Schuman = 'Schuman',
  Trash   = 'Trash',
};