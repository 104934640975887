import * as auth0 from 'auth0-js';
import { Auth0DecodedHash, Auth0UserProfile } from 'auth0-js';
import Noty from 'noty';
import { EnvService } from '../EnvService';
import history from '../history';
import { Logger } from '../Logger';
import { GuestUser } from '../User/GuestUser';
import { IUser } from '../User/IUser';
import { UserFactory } from '../User/UserFactory';

export default class Auth {
  public auth0 = new auth0.WebAuth({
    audience: EnvService.audience,
    clientID: EnvService.clientId,
    domain: EnvService.domain,
    redirectUri: EnvService.callbackUrl,
    responseType: 'token id_token',
    scope: 'openid profile email'
  });
  
  private USER_INFO = 'user_info';
  
  public login = () => {
    this.auth0.authorize();
  };
  
  public getToken = () => {
    return localStorage.getItem('access_token');
  };
  
  public handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        this.setUserInfo(authResult.accessToken, (_user) => {
          this.setSession(authResult);
          history.replace('/');
        });
      } else if (err) {
        Logger.error(`Error: ${err.error}. Check the console for further details.`);
        this.logout();
      }
    });
  };
  
  public setSession(authResult: Auth0DecodedHash) {
    if (authResult.expiresIn && authResult.accessToken) {
      // Set the time that the access token will expire at
      const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
      localStorage.setItem('id_token', authResult.idToken || '');
      localStorage.setItem('access_token', authResult.accessToken);
      localStorage.setItem('expires_at', expiresAt);
      // navigate to the home route
      history.replace('/');
    }
  }
  
  public logout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_info');
    // navigate to the home route
    history.replace('/');
  };
  
  public isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = localStorage.getItem('expires_at');
    if (expiresAt) {
      return new Date().getTime() < JSON.parse(expiresAt);
    }
    return false;
  };
  
  public getCurrentUser(): IUser {
    const userInfoJson = localStorage.getItem(this.USER_INFO);
    const workingfrom = localStorage.getItem('workingfrom');
    
    if (!userInfoJson) {
      return new GuestUser();
    }
    
    const userInfo = JSON.parse(userInfoJson) as Auth0UserProfile;
    
    const userFactory = new UserFactory();
    try {
      return userFactory.createLoggedInUser(userInfo, workingfrom);
    } catch (e) {
      Logger.error(e);
      const noty = new Noty({
        text: e.message,
        theme: 'semanticui',
        timeout: 2000,
        type: 'error'
      });
      noty.show();
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      localStorage.removeItem('user_info');
      return new GuestUser();
    }
  }
  
  private setUserInfo(token: string, cb: (user: Auth0UserProfile) => void) {
    this.auth0.client.userInfo(token, (err, user) => {
      if (err) {
        Logger.warn('Could not fetch user data');
        this.logout();
      }
      localStorage.setItem('user_info', JSON.stringify(user));
      cb(user);
    });
  }
}

