import { ReactNode } from 'react';
import * as React from 'react';
import { IInventoryError } from '../../../../../models/IInventoryError';
import { IApiClient } from '../../../../../shared/Api/IApiClient';

// region Typings
interface IProps {
  apiClient: IApiClient,
  children: ReactNode,
  force?: boolean,
}

interface IState {
  errors: IInventoryError[]
}

// endregion

export class InventoryErrorsAlert extends React.Component<IProps, IState> {
  public state = {errors: [] as IInventoryError[]};
  // https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
  private _mounted = false;
  
  public render() {
    const {force, children} = this.props;
    if (!force) {
      const show = this.state.errors.filter(e => e.reviewCount === 0).length > 0;
      return show ? <>{children}</> : <span/>;
    }
    
    return <>{children}</>
  }
  
  public componentDidMount(): void {
    this._mounted = true;
    const client = this.props.apiClient.inventoryErrors;
    client.queryInventoryErrorsAsync().then(errors => {
      if (this._mounted) {
        this.setState({errors});
      }
    });
  }
  
  public componentWillUnmount(): void {
    this._mounted = false;
  }
}