import Axios from 'axios';
import { EnvService } from '../EnvService';
import { Roles } from '../Roles';
import { GuestUser } from '../User/GuestUser';
import { IUser } from '../User/IUser';
import { AdminInventoryErrorsRepository } from './AdminInventoryErrorsRepository';
import { ApiClient } from './ApiClient';
import { IApiClient } from './IApiClient';
import { PulpWarehouse } from './PulpWarehouse';

export class ApiClientBuilder {
  private token: string = '';
  private location: PulpWarehouse = '' as PulpWarehouse;
  private user: IUser = new GuestUser();
  
  public addToken(token: string): ApiClientBuilder {
    this.token = token;
    return this;
  }
  
  public addLocation(location: PulpWarehouse): ApiClientBuilder {
    this.location = location;
    return this;
  }
  
  public addUnauthorizedInterceptor(_cb: () => void): ApiClientBuilder {
    return this;
  }
  
  public addUser(user: IUser): ApiClientBuilder {
    this.user = user;
    return this;
  }
  
  public build(): IApiClient {
    if (!this.token || this.token.length === 0) {
      // throw new Error('Please provide a token for authentication');
    }
    
    const axiosInstance = Axios.create({
      baseURL: EnvService.api,
      headers: {'Authorization': `Bearer ${this.token}`}
    });
    
    const client = new ApiClient(axiosInstance);
    
    if (this.user.hasRole(Roles.StoreManager)) {
      client.inventoryErrors = new AdminInventoryErrorsRepository(axiosInstance);
    }
    
    if (this.location) {
      client.location = this.location;
    }
    
    return client;
  }
}
