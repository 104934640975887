import * as React from 'react';
import { ChangeEvent, Component, SyntheticEvent } from 'react';
import { Button, Dropdown, DropdownProps, Form, Modal } from 'semantic-ui-react';

import { IProductDropDown, IProductRatio } from '../../../../../../../models/IProductRatio';

interface IProps {
  open: boolean;
  selectedProductRatio: IProductRatio;
  products: IProductDropDown[];
  onChange: (productRatio: IProductRatio) => void;
  onSave: () => void;
  onCancel: () => void;
}

export class ProductRatioModal extends Component<IProps> {
  public render() {
    const open = this.props.open;
    const productsOptions = this.props.products.map(p => ({key: p.sku, text: `${p.name} (${p.sku})`, value: p.sku}));
    const modalTitle = this.props.selectedProductRatio.id > 0
      ? 'Editer un produit'
      : 'Ajouter un produit'
    const ratio = this.props.selectedProductRatio.ratio * 100;
    
    return (
      <Modal
        open={open}
        centered={false}>
        <Modal.Header>{modalTitle}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Produit</label>
              <Dropdown
                fluid selection search
                name="produitId"
                placeholder="Produit"
                options={productsOptions}
                value={this.props.selectedProductRatio.productSku}
                onChange={this.handleProductChange}/>
            </Form.Field>
            <Form.Field>
              <label>Ratio (en %)</label>
              <input
                name="ratio"
                placeholder="Ratio"
                type="number"
                min="0"
                max="100"
                value={ratio}
                onChange={this.handleRatioChange}/>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.onCancel}>Annuler</Button>
          <Button primary onClick={this.props.onSave}>Enregistrer</Button>
        </Modal.Actions>
      </Modal>
    );
  }
  
  private handleProductChange = (e: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const selectedProductSku = data.value as string;
    const product = this.props.products.find(p => p.sku === selectedProductSku);
    
    if (product) {
      this.props.onChange(Object.assign(this.props.selectedProductRatio, {
        productName: product.name,
        productSku: product.sku,
      }))
    }
  }
  
  private handleRatioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const ratio = +event.target.value / 100;
    this.props.onChange(Object.assign(this.props.selectedProductRatio, {ratio}))
  }
}