import { Auth0UserProfile } from 'auth0-js';
import { PulpWarehouse } from '../Api/PulpWarehouse';
import { ALoggedInUser } from './ALoggedInUser';

/**
 * A device user is a user that is linked to  a device.
 * In stores, staff members use a tablet that is logged with the account of the store
 * not the account of the staff member.
 */
export class DeviceUser extends ALoggedInUser {
  private location: PulpWarehouse;
  
  constructor(userProfile: Auth0UserProfile, location: PulpWarehouse) {
    super(userProfile);
    this.location = location;
  }

  public canCreateShipmentFrom(): PulpWarehouse[] {
    return [this.location];
  }

  public shouldImpersonate(): boolean {
    return false;
  }

  public getLocation(): PulpWarehouse | undefined {
    return this.location;
  }

  get workingFrom(): PulpWarehouse {
    return this.location;
  }
}