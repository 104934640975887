import { AxiosInstance } from 'axios';

import { IInventoryItem } from '../../models/IInventoryItem';
import {IProductRatio} from '../../models/IProductRatio';
import { IInventoryItemsRepository } from './IInventoryItemsRepository';

export class InventoryItemsRepository implements IInventoryItemsRepository {
  constructor(private _http: AxiosInstance) {
  }

  public async queryInventoryItemsAsync(includeInactive?: boolean): Promise<IInventoryItem[]> {
    const response = await this._http.get('v1.0/inventory-items', {params: {includeInactive}});
    return response.data as IInventoryItem[];
  }

  public async getTagsAsync(): Promise<string[]> {
    const response = await this._http.get('v1.0/inventory-items/tags');
    return response.data as string[];
  }

  public async getInventoryItemAsync(id: number): Promise<IInventoryItem> {
    const response = await this._http.get(`v1.0/inventory-items/${id}`);
    return response.data as IInventoryItem;
  }

  public async saveInventoryItemAsync(inventoryItem: IInventoryItem): Promise<IInventoryItem> {
    const path = 'v1.0/inventory-items';
    let response;

    if(inventoryItem.id > 0) {
      response = await this._http.put(`${path}/${inventoryItem.id}`, inventoryItem);
    } else {
      response = await this._http.post(path, inventoryItem);
    }

    return response.data as IInventoryItem;
  }

  public async saveProductRatio(inventoryItemId: number, productRatio: IProductRatio): Promise<IProductRatio> {
    const path = `v1.0/inventory-items/${inventoryItemId}/products`
    let response;

    if(productRatio.id > 0) {
      response = await this._http.put(`${path}/${productRatio.id}`, productRatio);
    } else {
      response = await this._http.post(path, productRatio);
    }

    return response.data as IProductRatio;
  }

  public async deleteProductRatio(inventoryItemId: number, productRatio: IProductRatio): Promise<void> {
    await this._http.delete(`v1.0/inventory-items/${inventoryItemId}/products/${productRatio.id}`);
  }
}