import * as React from 'react';
import {Route, RouteComponentProps, Switch} from 'react-router';
import {InventoryItem} from './screens/InventoryItem';
import {InventoryItems} from './screens/InventoryItems';

export function InventoryItemsRoute(props: RouteComponentProps) {
  return (
      <Switch>
        <Route path={props.match.path + '/:id'} component={InventoryItem}/>
        <Route path={props.match.path} component={InventoryItems}/>
      </Switch>
  );
}