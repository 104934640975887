import { AxiosInstance } from 'axios';

import {IProductDropDown} from '../../models/IProductRatio';
import {IProductRepository} from './IProductRepository';


export class ProductRepository implements IProductRepository {
  constructor(private _http: AxiosInstance) {
  }

  public async getProductsAsync(): Promise<IProductDropDown[]> {
    const response = await this._http.get('v1.0/products');
    return response.data as IProductDropDown[];
  }
}
