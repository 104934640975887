import * as React from 'react';
import { Button, Dimmer, Header, Icon, List, Loader, Segment } from 'semantic-ui-react';
import { IShipment } from '../shared/models/IShipment';

interface IProps {
  items: IShipment[],
  onReceive: (uuid: string) => void,
  loading?: boolean,
}

export function ListOfArrivals(props: IProps) {
  const arrivals = props.items.map(i => {
      function handleClick() {
        props.onReceive(i.uuid);
      }

      return <List.Item key={i.uuid} id="arrivals">
        <List.Content floated="right">
          <Button size="mini" onClick={handleClick}>Réceptionner</Button>
        </List.Content>
        <List.Icon name="sign in"/>
        <List.Content>
          <List.Header>{i.origin}</List.Header>
          <List.Description>
            <small>
              en provenance de {i.origin}
            </small>
          </List.Description>
        </List.Content>
      </List.Item>
    }
  );

  const header = <Header as="h5" attached="top">En transit</Header>;

  if (!props.loading && arrivals.length === 0) {
    return (<div>
        {header}
        <Segment attached>
          <Header icon style={{display: 'block'}}>
            <Icon name="sticker mule" style={{fontSize: '2em'}}/>
            <Header.Subheader>
              Pas de livraison en transit
            </Header.Subheader>
          </Header>
        </Segment>
      </div>
    );
  }

  if (props.loading) {
    return (
      <div>
        {header}
        <Segment attached style={{minHeight: '100px'}}>
          <Dimmer active inverted>
            <Loader>Chargement des envois & livraisons en transit</Loader>
          </Dimmer>
        </Segment>
      </div>
    );
  }

  return <div>
    {header}
    <Segment attached>
      <List relaxed divided>{arrivals}</List>
    </Segment>
  </div>;
}
