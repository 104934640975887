export interface IEnvProperties {
  AUTH_CLIENTID: string;
  AUTH_AUDIENCE: string;
  AUTH_CALLBACK_URL: string;
  AUTH_DOMAIN: string;
  API: string;
  BACKEND_HOST: string;
}

export type WindowWithEnv = Window & { env: IEnvProperties };

const _window: WindowWithEnv = window as any;

export class EnvService {
  public static audience = _window.env.AUTH_AUDIENCE;
  public static callbackUrl = _window.env.AUTH_CALLBACK_URL;
  public static domain = _window.env.AUTH_DOMAIN;
  public static api = _window.env.API;
  public static backendHost = _window.env.BACKEND_HOST;
  public static clientId = _window.env.AUTH_CLIENTID;
}
