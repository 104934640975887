import { DetailedHTMLProps, HTMLAttributes } from 'react';
import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { Form, Modal } from 'semantic-ui-react';
import { PulpWarehouse } from '../../../../../shared/Api/PulpWarehouse';
import { IUser } from '../../../../../shared/User/IUser';
import { DestinationSelector, OriginSelector } from './OriginSelector';

// region Typings
interface INewShipmentModalProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  open: boolean,
  onClose: () => void,
  optionsForOriginSelector: Array<{ text: PulpWarehouse; value: PulpWarehouse }>,
  selected: PulpWarehouse,
  currentUser: IUser,
  onSelected: (value: PulpWarehouse) => void,
  // TODO: rename
  onSelected1: (value: PulpWarehouse) => void,
  b: boolean,
  onSubmit: () => Promise<void>
}

// endregion

export class NewShipmentModal extends React.Component<INewShipmentModalProps> {
  public render() {
    return <Modal open={this.props.open} onClose={this.props.onClose} id={this.props.id}>
      <Modal.Header>Create a shipment</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field>
              <label>Origine</label>
              <OriginSelector options={this.props.optionsForOriginSelector}
                              selected={this.props.selected}
                              currentUser={this.props.currentUser}
                              onSelected={this.props.onSelected}/>
            </Form.Field>
            <Form.Field>
              <label>Destination</label>
              <DestinationSelector exclude={this.props.selected}
                                   currentUser={this.props.currentUser}
                                   onSelected={this.props.onSelected1}/>
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={!this.props.b} onClick={this.props.onSubmit}>Go</Button>
      </Modal.Actions>
    </Modal>;
  }
}
