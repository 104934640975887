import * as React from 'react';
import { List } from 'semantic-ui-react';

interface IProps {
  tags: string[];
  selectedTag: string;
  onChange: (selectedTag: string) => void
}


export function TagsFilter(props: IProps) {

  const tags = props.tags.map(tag => (
      <List.Item
          key={tag}
          active={tag === props.selectedTag}
          onClick={() => props.onChange(tag)}>
        <List.Content>
          {tag}
        </List.Content>
      </List.Item>
  ));

  return (
      <section>
        <h3>Tags</h3>
        <List selection verticalAlign="middle" relaxed>
          <List.Item
              active={props.selectedTag === ''}
              onClick={() => props.onChange('')}>
            <List.Content>
              Tous
            </List.Content>
          </List.Item>
          {tags}
        </List>
      </section>
  )
}