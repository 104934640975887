import { AxiosInstance } from 'axios';
import {
  IInventorySession,
  IJsonInventorySession
} from '../../screens/App/screens/Shipments/shared/models/IInventorySession';
import { IInventorySessionsRepository } from './IInventorySessionsRepository';
import { PulpWarehouse } from './PulpWarehouse';

export class InventorySessionsRepository implements IInventorySessionsRepository {
  set location(value: PulpWarehouse) {
    this._location = value;
  }
  
  private _location: PulpWarehouse | undefined;
  
  constructor(private _http: AxiosInstance) {
  }
  
  public async getInventorySessionsAsync(): Promise<IInventorySession[]> {
    const url = 'v1.0/inventory-sessions';
    
    const response = await this._http.get(url);
    
    return response.data.map((json: IJsonInventorySession) => {
      return {
        end: new Date(Date.parse(json.end)),
        inventorySessionId: json.inventorySessionId,
        location: PulpWarehouse[json.location],
        start: new Date(Date.parse(json.start)),
      }
    }) as IInventorySession[];
  }
  
  public async closeInventorySessionAsync(): Promise<IInventorySession> {
    const url = `v1.0/locations/${this._location}/inventory-sessions`;
    const response = await this._http.post(url);
    return response.data as IInventorySession;
  }
  
  public async getCurrent(location: PulpWarehouse): Promise<IInventorySession> {
    const url = 'v1.0/inventory-sessions/current';
    const response = await this._http.get(url, {params: {location: this._location}});
    return response.data as IInventorySession;
  }
  
  public async getSummary(sessionId: number, itemId: number): Promise<any> {
    const url = `v1.0/inventory-sessions/${sessionId}/summaries`;
    const response = await this._http.get(url, {params: {itemId}});
    return response.data;
  }
}