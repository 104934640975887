import { PulpWarehouse } from '../Api/PulpWarehouse';
import { Roles } from '../Roles';
import { ALoggedInUser } from './ALoggedInUser';

/**
 * A user that is not bound to its device. That user could loggin from anywhere and is not restricted to one tablet.
 */
export class NonDeviceUser extends ALoggedInUser {
  public canCreateShipmentFrom(): PulpWarehouse[] {
    if (this.hasRole(Roles.StoreManager)) {
      return Object.keys(PulpWarehouse).map(l => PulpWarehouse[l]);
    } else {
      return [];
    }
  }

  public getLocation(): PulpWarehouse | undefined {
    return this.workingFrom;
  }

  public shouldImpersonate(): boolean {
    return this.hasRole(Roles.Impersonate);
  }

  get workingFrom(): PulpWarehouse {
    return PulpWarehouse[this.userProfile.name];
  }
}