import * as React from 'react';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import Auth from '../../shared/Auth/Auth';

export function LoginScreen({auth}: { auth: Auth }) {
  return (
    <div className='login-form'>
      <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
      <Grid textAlign='center' style={{height: '100%'}} verticalAlign='middle'>
        <Grid.Column style={{maxWidth: 450}}>
          <Segment stacked>
            <Header as='h2' icon textAlign='center'>
              <Icon name='users' circular />
              <Header.Content>Welcome to PulpHQ</Header.Content>
            </Header>

            <Button onClick={auth.login}>You need to login</Button>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}