import { AxiosInstance } from 'axios';
import { IInventoryError } from '../../models/IInventoryError';
import { IEodShipmentsByProductResponse, IShipmentEntry } from './IEodShipmentsByProduct';
import { IInventoryErrorsRepository } from './IInventoryErrorsRepository';
import { PulpWarehouse } from './PulpWarehouse';


export abstract class AInventoryErrorsRepository implements IInventoryErrorsRepository {
  set location(value: PulpWarehouse) {
    this._location = value;
  }
  
  protected _location: PulpWarehouse | undefined;
  
  constructor(protected _http: AxiosInstance) {
  }
  
  public queryInventoryErrorsAsync(force?: boolean): Promise<IInventoryError[]> {
    if (!this._location) {
      throw new Error('Please set the location on the ApiClient before using it.');
    }
    
    const url = `v1.0/locations/${this._location}/inventory-errors`;
    
    const params = force ? {params: {force}} : {params: {}};
    
    return this._http.get(url, params).then(response => response.data);
  }
  
  public abstract saveInventoryCorrectionsAsync(itemId: number, entries: IShipmentEntry[]): Promise<IInventoryError[]>;
  
  public getEodShipmentsAsync(inventoryItemId: number): Promise<IEodShipmentsByProductResponse> {
    const url = `v1.0/locations/${this._location}/end-of-day/entries/${inventoryItemId}`;
    return this._http.get(url).then(response => response.data);
  }
}

