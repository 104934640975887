import { Auth0UserProfile } from 'auth0-js';
import { PulpWarehouse } from '../Api/PulpWarehouse';
import { Roles } from '../Roles';
import { IUser } from './IUser';

/**
 * A GuestUser is the equivalent of a NullUser.
 * For all of its api, it will return the most restrictive result.
 */
export class GuestUser implements IUser {
  public canCreateShipmentFrom(): PulpWarehouse[] {
    return [];
  }

  public getLocation(): PulpWarehouse | undefined {
    return undefined;
  }

  public getRoles(): string[] {
    return [];
  }

  public hasRole(role: Roles): boolean {
    return false;
  }

  public is(name: string): boolean {
    return false;
  }

  public get name() {
    return 'Guest';
  }

  public get profile() {
    return {} as Auth0UserProfile;
  }

  public shouldImpersonate(): boolean {
    return false;
  }

  public get workingFrom() {
    return PulpWarehouse['guest'];
  };
}