import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Form, Icon, Menu, Modal } from 'semantic-ui-react';
import { DestinationSelector } from '../../screens/App/screens/Shipments/components/OriginSelector';
import Auth from '../Auth/Auth';
import { AuthContext } from '../Auth/AuthContext';
import { CurrentUserContext } from '../CurrentUserContext';
import { Roles } from '../Roles';
import { IUser } from '../User/IUser';

export class TopMenu extends React.Component<RouteComponentProps> {
  public state = {
    openModal: false
  };

  public render() {
    let back: string;

    switch (this.props.match.path) {
      case '/shipments/:uuid':
        back = '/shipments';
        break;
      case '/inventory-items/:id':
        back = '/inventory-items';
        break;
      case '/marchandises':
      case '/shipments':
        back = '/';
        break;
      case '/inventory-errors':
        back = '/shipments';
      case '/dashboard':
        back = '';
        break;
      default:
        back = '/';
    }

    return (
      <AuthContext.Consumer>
        {(auth: Auth) => (
          <CurrentUserContext.Consumer>
            {({user, setWorkingFrom}: { user: IUser, setWorkingFrom: (s: string) => void }) => (
              <div>
                <Menu secondary pointing fixed="top" style={{backgroundColor: 'white'}}>
                  {back.length > 0 && (
                    <Menu.Item id="back">
                      <Link to={back}>
                        <Icon name="chevron left"/>
                      </Link>
                    </Menu.Item>
                  )}

                  <Menu.Item header>{this.props.children}</Menu.Item>

                  <Menu.Menu position="right">
                    <Menu.Item>
                      <Dropdown floating button icon="user outline" className="circular icon">
                        <Dropdown.Menu>
                          <Dropdown.Header>{user.name} [as <b>{user.workingFrom}]</b></Dropdown.Header>
                          <Dropdown.Divider/>
                          {user.hasRole(Roles.Impersonate) && (
                            <span>
                            <Dropdown.Item onClick={this.toggleModal}>Change Location</Dropdown.Item>
                            <Dropdown.Divider/>
                            </span>
                          )}
                          <Dropdown.Item onClick={auth.logout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Menu.Item>
                  </Menu.Menu>
                </Menu>

                <Modal open={this.state.openModal}>
                  <Modal.Header>Where are you working from?</Modal.Header>
                  <Modal.Content>
                    <Modal.Description>
                      <Form>
                        <Form.Field>
                          <label>Choose a store</label>
                          <DestinationSelector currentUser={user} onSelected={setWorkingFrom}/>
                        </Form.Field>
                      </Form>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button onClick={this.toggleModal}>fermer</Button>
                  </Modal.Actions>
                </Modal>
              </div>
            )}
          </CurrentUserContext.Consumer>
        )}
      </AuthContext.Consumer>
    );
  }

  private toggleModal = () => {
    this.setState({openModal: !this.state.openModal})
  }
}
