import { Auth0UserProfile } from 'auth0-js';
import { PulpWarehouse } from '../Api/PulpWarehouse';
import { Roles } from '../Roles';
import { ALoggedInUser } from './ALoggedInUser';
import { DeviceUser } from './DeviceUser';
import { ImpersonatedUser } from './ImpersonatedUser';
import { IUser } from './IUser';
import { IUserFactory } from './IUserFactory';
import { NonDeviceUser } from './NonDeviceUser';

export class UserFactory implements IUserFactory {
  /**
   * creates the appropriate User based on the user profile and the working from
   * location.
   * @param userProfile the user profile from auth0
   * @param workingfrom the location from which the user is working
   */
  public createLoggedInUser(userProfile: Auth0UserProfile, workingfrom: string | null): IUser {
    if (this.hasRole(userProfile, Roles.StoreManager)) {
      const user = new NonDeviceUser(userProfile);
      return workingfrom ? new ImpersonatedUser(user, workingfrom) : user;
    }
    
    if (this.hasRole(userProfile, Roles.Magasin)) {
      switch (userProfile.email) {
        case 'archi@pulp.be':
          return new DeviceUser(userProfile, PulpWarehouse.Archi);
        case 'madou@pulp.be':
          return new DeviceUser(userProfile, PulpWarehouse.Madou);
        case 'loi@pulp.be':
          return new DeviceUser(userProfile, PulpWarehouse.Loi);
        case 'lux@pulp.be':
          return new DeviceUser(userProfile, PulpWarehouse.Lux);
        case 'rogier@pulp.be':
          return new DeviceUser(userProfile, PulpWarehouse.Rogier);
        case 'schuman@pulp.be':
          return new DeviceUser(userProfile, PulpWarehouse.Schuman);
        case 'impe@pulp.be':
          return new DeviceUser(userProfile, PulpWarehouse.Impé);
        case 'atelier@pulp.be':
          return new DeviceUser(userProfile, PulpWarehouse.Atelier);
        default:
          break;
      }
    }
    
    throw new Error('there is a problem with your user profile. Please check roles and email.');
  }
  
  private hasRole(userProfile: Auth0UserProfile, role: Roles): boolean {
    return -1 !== (userProfile[ALoggedInUser.ROLE_CLAIM] || []).indexOf(role);
  }
}