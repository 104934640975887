import styled from 'styled-components';

const FlexColumns = styled.div`
flex-direction: column;
display: flex;
`;

const FlexFill = styled.div`
flex-grow: 1;
`;

const FlexCenter = styled.div` 
display: flex;
align-items: center;
`;

export const Flex = {
  Center: FlexCenter,
  Columns: FlexColumns,
  Fill: FlexFill,
};