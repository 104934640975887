import { PulpWarehouse } from '../../../../../../shared/Api/PulpWarehouse';
import { IShipment, ShipmentStatuses } from './IShipment';
import { IShipmentItem } from './IShipmentItem';
import { IShipmentJson } from './IShipmentJson';

export class ShipmentBase implements IShipment {
  public static createFromJson(json: IShipmentJson): IShipment {
    const shipment = new ShipmentBase();
    shipment.description = json.description;
    shipment.destination = PulpWarehouse[json.destination];
    shipment.origin = PulpWarehouse[json.origin];
    shipment.id = json.id;
    shipment.uuid = json.uuid;
    shipment.items = json.items;
    shipment.isReadOnly = json.readOnly;
    shipment.nextStatus = ShipmentStatuses[json.nextStatus];
    shipment.isEndofday = json.isEndOfDay;
    return shipment;
  }
  
  public description: string = '';
  public destination: PulpWarehouse = PulpWarehouse.Impé;
  public id: number = 0;
  public origin: PulpWarehouse = PulpWarehouse.Impé;
  public uuid: string = '';
  public createdAt: Date = new Date();
  public createdBy: string = '';
  public isReadOnly: boolean = true;
  public items: IShipmentItem[] = [];
  public lastUpdateAt: Date = new Date();
  public lastUpdatedBy: string = '';
  public status: string = '';
  public workday: Date = new Date();
  public nextStatus = ShipmentStatuses.New;
  public isEndofday = false;
}
