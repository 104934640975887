import Noty from 'noty';

const defaultOptions = {
  type: 'success'
};

export const toastService = {
  triggerToast(message: string, options?: any) {
    options = Object.assign({}, defaultOptions, options);
    const notification = new Noty({
      text: message,
      theme: 'semanticui',
      timeout: 2000,
      type: options.type,
    });
    notification.show();
  }
};