import * as React from 'react';
import { Route, RouteComponentProps, RouteProps, Router, Switch } from 'react-router';
import App from '../screens/App';
import { Callback } from '../screens/Callback/Callback';
import { LoginScreen } from '../screens/Login/loginScreen';
import Auth from '../shared/Auth/Auth';
import history from '../shared/history';

export const makeRoutes = (auth: Auth) => {
  function renderCallback(props: RouteProps) {
    if (props.location) {
      if (/access_token|id_token|error/.test(props.location.hash)) {
        auth.handleAuthentication();
      }
    }
    return <Callback {...props} />
  }
  
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/callback" render={renderCallback}/>
        <Route exact path="/login" render={() => <LoginScreen auth={auth}/>}/>
        <Route path="/" render={(props: RouteComponentProps) => <App auth={auth} {...props}/>}/>
      </Switch>
    </Router>
  );
};



