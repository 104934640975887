import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Confirm, Dimmer, Grid, Header, Icon, List, Loader, Segment } from 'semantic-ui-react';
import { IApiClient } from '../../../../../shared/Api/IApiClient';
import { FullScreen, TopMenu } from '../../../../../shared/components';
import { Padded } from '../../../../../shared/components/POS/PosView';
import { IInventorySession } from '../../Shipments/shared/models/IInventorySession';

// region Types
interface IProps extends RouteComponentProps {
  client: IApiClient;
}

interface IState {
  inventorySessions: IInventorySession[],
  inventorySession: IInventorySession,
  confirm: boolean,
  loading: boolean,
}

// endregion

export class InventorySessions extends React.Component<IProps, IState> {
  public state = {
    confirm: false,
    inventorySession: {} as IInventorySession,
    inventorySessions: [] as IInventorySession[],
    loading: false,
  };

  public async componentDidMount(): Promise<void> {
    this.setState({loading: true});
    const inventorySessions = await this.props.client.inventorySessions.getInventorySessionsAsync();
    this.setState({inventorySessions, loading: false});
  }

  public render() {
    const items = this.state.inventorySessions
      .filter(s => s.location)
      .map(s => {
        const handleClick = () => {
          this.handleCloseInventorySession(s);
        };

        return (
          <List.Item key={s.location}>
            <List.Content floated="right">
              <Button onClick={handleClick} size={'mini'}>Clôturer</Button>
            </List.Content>
            <List.Header>{s.location}</List.Header>
            <List.Content>
              <small>
                crée {s.start.toLocaleDateString()} à {s.start.toLocaleTimeString()}
              </small>
            </List.Content>
          </List.Item>
        )
      });

    return (
      <FullScreen>
        <TopMenu {...this.props}>Clôture du stock</TopMenu>
        <Padded>
          <Grid centered columns={2}>
            <Grid.Column>
              {this.state.loading && (
                <Segment style={{minHeight: '120px'}}>
                  <Dimmer active inverted>
                    <Loader inverted>loading</Loader>
                  </Dimmer>
                </Segment>
              )}

              {!this.state.loading && this.state.inventorySessions.length > 0 && (
                <Segment><List divided relaxed>{items}</List></Segment>
              )}
              {!this.state.loading && this.state.inventorySessions.length === 0 && (
                <Segment>
                  <Header icon style={{display: 'block'}}>
                    <Icon name="rain" style={{fontSize: '2em'}}/>
                    <Header.Subheader>
                      Pas de session d'inventaire prête à clôturer
                    </Header.Subheader>
                  </Header>
                </Segment>
              )}


            </Grid.Column>
          </Grid>
        </Padded>
        <Confirm
          open={this.state.confirm}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />
      </FullScreen>
    );
  }

  private handleCloseInventorySession = (inventorySession: IInventorySession) => {
    this.setState({confirm: true, inventorySession});
  }

  private handleCancel = () => {
    this.setState({confirm: false});
  }

  private handleConfirm = async () => {
    this.setState({confirm: false, loading: true});
    try {
      await this.props.client.inventorySessions.closeInventorySessionAsync();
      const inventorySessions = await this.props.client.inventorySessions.getInventorySessionsAsync();
      this.setState({loading: false, inventorySessions});
    } catch (e) {
      this.setState({loading: false});
    }
  }
}