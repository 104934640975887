import * as React from 'react';
import {RouteComponentProps} from 'react-router';

import {ApiContext} from '../../../../../../shared/Api/ApiContext';
import {CurrentUserContext} from '../../../../../../shared/CurrentUserContext';
import {InventoryItemDetails} from './components/InventoryItemDetails';

export function InventoryItem(routeProps: RouteComponentProps) {
  return (
    <CurrentUserContext.Consumer>
      {(userCtx) => (
        <ApiContext.Consumer>
          {apiClient => (
            <InventoryItemDetails user={userCtx.user} apiClient={apiClient} {...routeProps} />
          )}
        </ApiContext.Consumer>
      )}
    </CurrentUserContext.Consumer>
  );
}