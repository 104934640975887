import * as React from 'react';
import {RouteComponentProps} from 'react-router';

import { ApiContext } from '../../../../../../shared/Api/ApiContext';
import { CurrentUserContext } from '../../../../../../shared/CurrentUserContext';
import { InventoryItemList} from './components/InventoryItemList';

export function InventoryItems(routeProps: RouteComponentProps) {
  return (
    <CurrentUserContext.Consumer>
      {(userCtx) => (
        <ApiContext.Consumer>
          {apiClient => (
            <InventoryItemList user={userCtx.user} apiClient={apiClient} {...routeProps} />
          )}
        </ApiContext.Consumer>
      )}
    </CurrentUserContext.Consumer>
  );
}