import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApiContext } from '../../../../../shared/Api/ApiContext';
import { CurrentUserContext } from '../../../../../shared/CurrentUserContext';
import { Shipments } from './Shipments';

export function InjectShipmentsDependencies(props: RouteComponentProps) {
  return (
    <CurrentUserContext.Consumer>
      {userContext => (
        <ApiContext.Consumer>
          {client => <Shipments {...props} apiClient={client} userContext={userContext}/>}
        </ApiContext.Consumer>
      )}
    </CurrentUserContext.Consumer>
  );
}