export const PosFilters = [
  {label: 'drinks', value: 'drink'},
  {label: 'hot drinks', value: 'hot drink'},
  {label: 'salades', value: 'salade'},
  {label: 'desserts', value: 'dessert'},
  {label: 'snacks', value: 'snack'},
  {label: 'sandwichs', value: 'sandwich'},
  {label: 'croissanterie', value: 'croissanterie'},
  {label: 'pâtisserie', value: 'pâtisserie'},
  {label: 'breakfast', value: 'breakfast'},
  // {label: 'stock nat hygiène', value: 'hygiène'},
  // {label: 'stock nat packaging', value: 'packaging'},
  // {label: 'stock nat papeterie', value: 'papeterie'},
  {label: 'accompagnements', value: 'accompagnements'},
];
