import { AxiosInstance } from 'axios';
import { IEodInventoryClient } from './IEodInventoryClient';
import { PulpWarehouse } from './PulpWarehouse';

export class EodInventoryClientBase implements IEodInventoryClient {
  set location(value: PulpWarehouse) {
    this._location = value;
  }
  
  private _location: PulpWarehouse | undefined;
  
  constructor(private http: AxiosInstance) {
  }
  
  public close(): Promise<void> {
    if(!this._location){
      throw new Error('Please set the location on the ApiClient before using it.');
    }
  
    return this.http
      .put(`v1.0/locations/${this._location}/end-of-day`, {})
      .then(response => response.data);
  }
  
  public start(): Promise<void> {
    if(!this._location){
      throw new Error('Please set the location on the ApiClient before using it.');
    }
    
    return this.http
      .post(`v1.0/locations/${this._location}/end-of-day`, {})
      .then(response => response.data);
  }
}