import * as React from 'react';
import { Component, ReactNode } from 'react';
import { Button, Grid, Modal, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { IInventoryItem } from '../../../models/IInventoryItem';
import { NullProduct } from '../../../screens/App/screens/Shipments/shared/models/NullProduct';
import { Flex } from '../Flex';
import { IPosViewFilter } from './IPosViewFilter';
import { IReceipt } from './IReceipt';
import { OverviewItem } from './OverviewItem';

// region Typings
type HandleSelectionHandler = (item: IInventoryItem) => void;
type HandleFilterHandler = (item: IPosViewFilter) => void;

interface IPosViewProps {
  filterFn: (p: IInventoryItem, v: string) => boolean,
  filterItems: IPosViewFilter[],
  items: IInventoryItem[],
  itemStyle?: (i: IInventoryItem) => {},
  itemClassName?: string[],
  onDeleteItem?: (itemId: string) => void,
  onItemSelected?: (i: IInventoryItem, value: number) => void,
  onItemSubmit?: (i: IInventoryItem) => void,
  readonly?: boolean,
  receipt: IReceipt,
  renderSelectedItem?: (p: IInventoryItem, value: number) => ReactNode,
  renderItem: (item: IInventoryItem, handleSelectionHandler: HandleSelectionHandler) => ReactNode,
  renderFilter: (filter: IPosViewFilter, handler: HandleFilterHandler) => ReactNode,
}

interface IPosViewState {
  filterValue: string;
  selected: IInventoryItem;
  selectedValue: number;
}

// endregion

export class PosView extends Component<IPosViewProps, IPosViewState> {
  public state = {
    filterValue: this.props.filterItems[0] ? this.props.filterItems[0].value : '',
    selected: new NullProduct(),
    selectedValue: 0,
  };
  
  public render() {
    const {filterValue} = this.state;
    
    const products = this.props.items
      .filter(p => this.props.filterFn(p, filterValue))
      .map(p => {
        const handler = this.handleProductSelection.bind(this, p);
        return (
          <Grid.Column key={p.id} style={{padding: '0.5rem'}}>
            {this.props.renderItem(p, handler)}
          </Grid.Column>
        );
      });
    
    const receiptItems = this.props.receipt.items.map(i => (
      <OverviewItem
        key={i.itemId}
        item={i}
        onDelete={this.props.onDeleteItem}
        disabled={this.props.readonly}
      />
    ));
    
    const filters = this.props.filterItems.map(f => {
      const handler = this.handleFilter.bind(this, f.value);
      return <Grid.Column key={f.value} style={{padding: '0.5rem'}}>
        {this.props.renderFilter(f, handler)}
      </Grid.Column>
    });
    
    const openModal = !(this.state.selected instanceof NullProduct);
    
    return (
      <PosContainer className="posContainer">
        <Modal onClose={this.handleProductSelection.bind(this, new NullProduct())}
               open={openModal}>
          {this.props.children}
          <Modal.Actions>
            <Button onClick={this.closeModal}>
              Close
            </Button>
            <Button
              primary
              id="theFormSubmit"
              form="theForm"
              type="submit"
              onClick={this.handleSubmit}
              disabled={this.props.readonly}
            >
              Submit
            </Button>
          </Modal.Actions>
        </Modal>
        
        <TopAligned>
          <Flex.Columns style={{height: 'calc(100% - 48px)'}}>
            <Flex.Fill>
              <Padded style={{paddingRight: '1rem', paddingBottom: '0.5rem'}}>
                <div id="posItems">
                  <Grid columns={7}>{products}</Grid>
                </div>
              </Padded>
            </Flex.Fill>
            
            <Padded style={{minHeight: '156px)', paddingRight: '1rem', paddingTop: '0.5rem'}}>
              <div id="posFilters">
                <Grid columns={7}>
                  {filters}
                </Grid>
              </div>
            </Padded>
          </Flex.Columns>
        </TopAligned>
        
        <TopAligned id="posOverviewPanel" style={{display: 'flex'}}>
          <Padded style={{width: '282px', paddingLeft: '1rem', paddingTop: '1.5rem'}}>
            <Flex.Columns style={{overflow: 'hidden', height: 'calc(100% - 48px)'}}>
              <Segment style={{marginBottom: '0.5rem', minHeight: '50px', overflow: 'auto'}}>
                <Flex.Fill style={{overflow: 'auto'}}>
                  <h4>Overview</h4>
                  {receiptItems}
                </Flex.Fill>
              </Segment>
              
              <Segment style={{marginTop: '0.5rem', minHeight: '50px'}}>
                <h4>Total {this.props.receipt.items.length}</h4>
              </Segment>
            </Flex.Columns>
          </Padded>
        </TopAligned>
      </PosContainer>
    );
  }
  
  private handleSubmit = () => {
    if (typeof this.props.onItemSubmit === 'function') {
      this.props.onItemSubmit(this.state.selected);
      this.setState({selected: new NullProduct()})
    }
  };
  
  private handleFilter = (filterValue: string) => {
    this.setState({filterValue})
  };
  
  private handleProductSelection = (selected: IInventoryItem) => {
    const item = this.props.receipt.items.find(i => i.itemName === selected.name);
    const selectedValue = item && item.amount ? item.amount : 0;
    this.setState({selected, selectedValue});
    if (typeof this.props.onItemSelected === 'function') {
      this.props.onItemSelected(selected, selectedValue);
    }
  }
  
  private closeModal = () => {
    this.setState({selected: new NullProduct()});
  }
}

export const FillPane = styled.div`
height: 100%;
width: 100%;
position: absolute;
left: 0;
top: 0;
`;

export const PosContainer = styled(FillPane)`
display: flex;
`;

export const BigButton = styled(Button)`
height: 50px;
padding-left: 0.2em !important;
padding-right: 0.2em !important;
font-weight: 200 !important;
`;

export const Padded = styled.div`
padding: 2rem;
`;

const TopAligned = styled.div`
vertical-align: top;
display: table-cell;
`;

