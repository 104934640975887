import * as React from 'react';

import {IProductRatio} from '../../../../../../../models/IProductRatio';

export interface IProps {
  productRatios: IProductRatio[];
}

export function ProductRatios(props: IProps) {
  const productRatios = props.productRatios.map(pr => pr.productName + ' (' + pr.productSku + ')')

  return <span>{productRatios}</span>
}