import React, { ReactNode } from 'react';

interface IProps {
  nav: ReactNode,
  body: ReactNode
}

export function Layout(props: IProps) {
  return (
    <div style={{position: 'fixed', height: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
      <div style={{minHeight: '50px'}}>
        {props.nav}
      </div>
      <div style={{overflow: 'auto'}}>
        {props.body}
      </div>
    </div>
  )
}
