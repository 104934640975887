import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApiContext } from '../../../../shared/Api/ApiContext';
import { CurrentUserContext } from '../../../../shared/CurrentUserContext';
import { Marchandises } from './components/Marchandises';

export function MarchandisesRoute(props: RouteComponentProps) {
  return <CurrentUserContext.Consumer>
    {userContext => (
      <ApiContext.Consumer>
        {client => <Marchandises {...props} apiClient={client} userContext={userContext}/>}
      </ApiContext.Consumer>
    )}
  </CurrentUserContext.Consumer>;
}
