import Noty from 'noty';
import * as React from 'react';
import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Grid, Segment, Table } from 'semantic-ui-react';

import { IInventoryItem, InventoryItem } from '../../../../../../../models/IInventoryItem';
import { IProductDropDown, IProductRatio, ProductRatio } from '../../../../../../../models/IProductRatio';
import { IApiClient } from '../../../../../../../shared/Api/IApiClient';
import { BottomRight, TopMenu } from '../../../../../../../shared/components';
import { Layout } from '../../../../../../../shared/components/Layout';
import { Padded } from '../../../../../../../shared/components/POS/PosView';
import { IUser } from '../../../../../../../shared/User/IUser';
import { InventoryItemForm } from '../../shared/InventoryItemForm';
import { DeleteButton } from './DeleteButton';
import { ProductRatioModal } from './ProductRatioModal';

interface IProps {
  apiClient: IApiClient;
  user: IUser;
}

interface IState {
  modalOpen: boolean;
  inventoryItem: IInventoryItem;
  productRatios: IProductRatio[];
  selectedProductRatio: IProductRatio;
  products: IProductDropDown[];
  tags: string[];
}

export class InventoryItemDetails extends Component<IProps & RouteComponentProps, IState> {
  public state: IState = {
    inventoryItem: new InventoryItem(),
    modalOpen: false,
    productRatios: [],
    products: [],
    selectedProductRatio: new ProductRatio(),
    tags: []
  };

  public async componentDidMount(): Promise<void> {
    const {apiClient} = this.props;
    const id = this.props.match.params['id'];
    Promise
      .all([
        apiClient.inventoryItems.getInventoryItemAsync(id),
        apiClient.inventoryItems.getTagsAsync(),
        apiClient.products.getProductsAsync()
      ])
      .then(([inventoryItem, tags, products]) => {
        this.setState({
          inventoryItem,
          productRatios: [...inventoryItem.productRatios],
          products,
          tags
        });
      });
  }

  public render() {
    return <Layout nav={this.renderNav()} body={this.renderBody()}/>;
  }

  private renderNav() {
    return <TopMenu {...this.props}>Article</TopMenu>;
  }

  private renderBody() {
    const tags = this.state.tags;
    const inventoryItem = this.state.inventoryItem;
    const productRatios = this.state.productRatios;
    const productRows = productRatios.map(p => (
      <Table.Row key={p.productSku}>
        <Table.Cell>
          {p.productName} ({p.productSku})
        </Table.Cell>
        <Table.Cell>
          {p.ratio * 100}%
        </Table.Cell>
        <Table.Cell>
          <DeleteButton icon="trash" floated="right" basic size="mini" onConfirm={() => this.handleDeleteProduct(p)}/>
          <Button icon="pencil" floated="right" basic size="mini" onClick={() => this.handleEditProduct(p)}/>
        </Table.Cell>
      </Table.Row>
    ));
    return <Padded>
      <Grid container>
        <Grid.Column>
          <Segment>
            <InventoryItemForm
              inventoryItem={inventoryItem}
              tags={tags}
              onChange={this.handleInventoryItemChange}/>
            <br/>
            <Button type="submit" primary onClick={this.handleSaveInventoryItem}>Enregistrer</Button>
            <Button type="button" onClick={this.handleBackToList} floated="right">Retour à la liste</Button>
          </Segment>
          <Table compact>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell width={8}>Produit</Table.HeaderCell>
                <Table.HeaderCell width={4}>Ratio</Table.HeaderCell>
                <Table.HeaderCell width={4}/>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {productRows}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
      <BottomRight>
        <Button onClick={this.handleNewProduct} circular color="pink" icon="plus"/>
      </BottomRight>
      <ProductRatioModal
        open={this.state.modalOpen}
        selectedProductRatio={this.state.selectedProductRatio}
        products={this.state.products}
        onChange={this.handleProductChange}
        onSave={this.handleSaveProduct}
        onCancel={this.handleCancelProduct}
      />
    </Padded>;
  }

  private handleInventoryItemChange = (updatedItem: IInventoryItem) => {
    this.setState({
      inventoryItem: updatedItem
    })
  };

  private handleSaveInventoryItem = async () => {
    const {apiClient} = this.props;
    const updatedItem = await apiClient.inventoryItems.saveInventoryItemAsync(this.state.inventoryItem);
    this.setState({inventoryItem: updatedItem})
    this.notify('L\'article a bien été enregistré');
  };

  private handleBackToList = () => {
    this.props.history.push('/inventory-items');
  };

  private handleEditProduct = (product: IProductRatio) => {
    this.setState({
      modalOpen: true,
      selectedProductRatio: {...product},
    })
  };

  private handleProductChange = (updatedProduct: IProductRatio) => {
    this.setState({
      selectedProductRatio: updatedProduct
    })
  };

  private handleDeleteProduct = async (productRatio: IProductRatio) => {
    const {apiClient} = this.props;
    const {inventoryItem} = this.state;
    await apiClient.inventoryItems.deleteProductRatio(inventoryItem.id, productRatio);
    const productRatios = this.state.productRatios.filter(pr => pr.id !== productRatio.id);
    this.setState({
      productRatios
    })
  };

  private handleNewProduct = () => {
    this.setState({
      modalOpen: true,
      selectedProductRatio: new ProductRatio(),
    })
  }

  private handleSaveProduct = async () => {
    const {apiClient} = this.props;
    const {inventoryItem} = this.state;
    const productRatio = this.state.selectedProductRatio;
    const productRatios = [...this.state.productRatios];

    if (productRatio.id === 0) {
      const createdProductRatio = await apiClient.inventoryItems.saveProductRatio(inventoryItem.id, productRatio);
      productRatios.push(createdProductRatio)
    } else {
      const updatedProductRatio = await apiClient.inventoryItems.saveProductRatio(inventoryItem.id, productRatio);
      const p = productRatios.find(pr => pr.id === updatedProductRatio.id);
      if (p) {
        const index = productRatios.indexOf(p);
        productRatios[index] = updatedProductRatio;
      }
    }

    this.setState({
      modalOpen: false,
      productRatios
    })

    this.notify('Le produit a bien été enregistré')
  };

  private handleCancelProduct = () => {
    this.setState({
      modalOpen: false
    })
  }

  private notify(message: string) {
    new Noty({
      text: message,
      theme: 'semanticui',
      timeout: 2000,
      type: 'success',
    }).show();
  }
}
