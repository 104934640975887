import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApiContext } from '../../../../shared/Api/ApiContext';
import { CurrentUserContext } from '../../../../shared/CurrentUserContext';
import { Dashboard } from './components/Dashboard';

export function DashboardRoute(props: RouteComponentProps) {
  // No subroutes defined, this is actually just
  // a placeholder to comply to the conventions
  return (
    <CurrentUserContext.Consumer>
      {userContext => (
        <ApiContext.Consumer>
          {apiClient => <Dashboard {...props} userContext={userContext} apiClient={apiClient}/>}
        </ApiContext.Consumer>
      )}
    </CurrentUserContext.Consumer>
  );
}