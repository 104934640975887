import * as React from 'react';
import {Component} from 'react';
import {Button, ButtonProps, Header, Icon, Modal} from 'semantic-ui-react';

interface IProps {
  onConfirm: () => void;
}

interface IState {
  open: boolean;
}

export class DeleteButton extends Component<IProps & ButtonProps, IState> {

  public state: IState = {
    open: false
  }

  public render() {
    const { onConfirm, children, ...otherProps } = this.props;

    return (
        <Modal
            trigger={(
                <Button {...otherProps} onClick={this.handleOpen}>
                  {children}
                </Button>
            )}
            basic
            size="small"
            open={this.state.open}
            onClose={this.handleClose}>
          <Header icon="trash" content="Supprimer produit"/>
          <Modal.Content>
            <p>
              Êtes-vous certain de vouloir supprimer ce produit?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={this.handleClose}>
              <Icon name="remove"/> Non
            </Button>
            <Button color="green" inverted onClick={this.handleConfirm}>
              <Icon name="checkmark"/> Oui
            </Button>
          </Modal.Actions>
        </Modal>
    );
  }

  private handleOpen = () => this.setState({open: true})

  private handleClose = () => this.setState({open: false})

  private handleConfirm = () => {
    this.props.onConfirm()
    this.handleClose()
  }
}
