import * as React from 'react';
import { Form, Modal } from 'semantic-ui-react';
import { IUser } from '../../../../../shared/User/IUser';
import { DestinationSelector } from './OriginSelector';

export class ImpersonateModal extends React.Component<{ user: IUser, onSelected: (v: string) => void }> {
  public render() {
    return <Modal open={this.props.user.shouldImpersonate()}>
      <Modal.Header>Where are you working from?</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field>
              <label>Choose a store</label>
              <DestinationSelector currentUser={this.props.user} onSelected={this.props.onSelected}/>
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>;
  }
}