import { PulpWarehouse } from '../../../../../../shared/Api/PulpWarehouse';
import { IShipmentItem } from './IShipmentItem';

export enum ShipmentStatuses {
  Closed   = 'Closed',
  New      = 'New',
  Received = 'Received',
  Transit  = 'Transit',
}

export interface IShipment {
  description: string;
  id: number;
  uuid: string;
  origin: PulpWarehouse;
  destination: PulpWarehouse;
  items: IShipmentItem[];
  isEndofday: boolean;
  workday: Date;
  createdAt: Date;
  lastUpdateAt: Date;
  createdBy: string;
  lastUpdatedBy: string;
  status: string;
  isReadOnly: boolean;
  nextStatus: ShipmentStatuses;
}

