import * as React from 'react';
import {ChangeEvent, Component, FormEvent, SyntheticEvent} from 'react';
import {Checkbox, CheckboxProps, Dropdown, DropdownProps, Form} from 'semantic-ui-react';

import {IInventoryItem} from '../../../../../../models/IInventoryItem';

interface IProps {
  inventoryItem: IInventoryItem;
  onChange: (inventoryItem: IInventoryItem) => void;
  tags: string[];
}

export class InventoryItemForm extends Component<IProps> {

  public render() {
    const inventoryItem = this.props.inventoryItem;
    const tagsOptions = this.props.tags.map(c => ({key: c, text: c, value: c}));

    return (
        <Form>
          <Form.Field>
            <label>Nom</label>
            <input
                name="name"
                placeholder="Entrez un nom"
                value={inventoryItem.name}
                onChange={this.handleNameChange}/>
          </Form.Field>
          <Form.Field>
            <Checkbox
                name="active"
                label="Actif"
                checked={inventoryItem.active}
                onChange={this.handleActiveChange}/>
          </Form.Field>
          <Form.Field>
            <label>Tags</label>
            <Dropdown
                fluid multiple selection search
                name="tags"
                placeholder="Selectionez des tags"
                options={tagsOptions}
                value={inventoryItem.tags}
                onChange={this.handleTagsChange}/>
          </Form.Field>
        </Form>
    )
  }

  private handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value as string;
    this.props.onChange({
      ...this.props.inventoryItem,
      name
    });
  }

  private handleActiveChange = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const active = data.checked as boolean;
    this.props.onChange({
      ...this.props.inventoryItem,
      active
    });
  }

  private handleTagsChange = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const tags = data.value as string[];
    this.props.onChange({
      ...this.props.inventoryItem,
      tags
    });
  }
}