import { IInventoryError } from '../../models/IInventoryError';
import { IShipmentEntry } from './IEodShipmentsByProduct';
import { AInventoryErrorsRepository } from './InventoryErrorsRepository';

export class UserInventoryErrorsRepository extends AInventoryErrorsRepository {
  public saveInventoryCorrectionsAsync(itemId: number, entries: IShipmentEntry[]): Promise<IInventoryError[]> {
    const shipmentItemResources = entries.map(entry => ({
      amount: entry.amount,
      productId: itemId,
      shipmentId: entry.shipmentId
    }));
    const url = `v1.0/locations/${this._location}/inventory-errors/corrections`;
    return this._http.post(url, shipmentItemResources).then(response => response.data);
  }
  
}