import * as React from 'react';
import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Dimmer, Form, Grid, Loader, Modal, Segment } from 'semantic-ui-react';
import { IInventoryError } from '../../../../../models/IInventoryError';
import { IInventoryItem } from '../../../../../models/IInventoryItem';
import { IApiClient } from '../../../../../shared/Api/IApiClient';
import { IInventoryActivity, IShipmentEntry } from '../../../../../shared/Api/IEodShipmentsByProduct';
import { FullScreen, TopMenu } from '../../../../../shared/components';
import { IPosViewFilter } from '../../../../../shared/components/POS/IPosViewFilter';
import { PosFilters } from '../../../../../shared/components/POS/PosFilters';
import { BigButton, PosView } from '../../../../../shared/components/POS/PosView';
import { Logger } from '../../../../../shared/Logger';
import { Roles } from '../../../../../shared/Roles';
import { toastService } from '../../../../../shared/toastService';
import { IUser } from '../../../../../shared/User/IUser';
import { IInventorySession } from '../../Shipments/shared/models/IInventorySession';
import { NullProduct } from '../../Shipments/shared/models/NullProduct';
import { Entries } from './Entries';
import { PreviousSession } from './PreviousSession';

import './inventoryErrors.css';

// region Typings
interface IProps {
  apiClient: IApiClient,
  user: IUser
}

interface IState {
  showPreviousSession: boolean;
  activity: IInventoryActivity,
  inventoryErrors: IInventoryError[],
  inventoryItems: IInventoryItem[],
  inventorySession: IInventorySession,
  entries: IShipmentEntry[]
  inventoryItem?: IInventoryItem,
  loadingEntries: boolean,
}

// endregion


function filter(product: IInventoryItem, value: string) {
  return product.tags.indexOf(value) > -1;
}

export class InventoryErrors extends Component<IProps & RouteComponentProps, IState> {
  public static ALL_ERRORS_CORRECTED = 'Toutes les erreurs ont été revues';
  private static filters = PosFilters;
  
  public state: IState = {
    activity: {} as IInventoryActivity,
    entries: [],
    inventoryErrors: [] as IInventoryError[],
    inventoryItems: [],
    inventorySession: {} as IInventorySession,
    loadingEntries: false,
    showPreviousSession: false,
  };
  
  public async componentDidMount(): Promise<void> {
    try {
      const {apiClient} = this.props;
      const [inventoryItems, inventoryErrors, inventorySession] = await Promise.all([
        apiClient.inventoryItems.queryInventoryItemsAsync(),
        apiClient.inventoryErrors.queryInventoryErrorsAsync(this.props.user.hasRole(Roles.StoreManager)),
        apiClient.inventorySessions.getCurrent(this.props.user.workingFrom),
      ]);
      this.setState({inventoryItems, inventoryErrors, inventorySession});
      return;
    } catch (e) {
      Logger.error(e.message);
      this.props.history.push('/dashboard');
    }
  }
  
  public render() {
    
    const receipt = {
      items: this.state.inventoryErrors.map((e: IInventoryError) => ({
        amount: e.amount,
        itemId: e.inventoryItem.id.toString(),
        itemName: e.inventoryItem.name,
      }))
    };
    
    const readonly = this.isReviewed(this.state.inventoryItem) && !this.props.user.hasRole(Roles.StoreManager);
    
    return <FullScreen>
      <TopMenu {...this.props}>Inventory Errors</TopMenu>
      <PosView
        filterItems={InventoryErrors.filters}
        filterFn={filter}
        items={this.state.inventoryItems}
        onItemSelected={this.handleItemSelected}
        onItemSubmit={this.handleItemSubmit}
        readonly={readonly}
        renderItem={(p, handler) => (
          <BigButton onClick={handler}
                     className={this.overrideItemStyle(p)}
                     fluid
          >{p.name}
          </BigButton>
        )}
        renderFilter={(f, handler) => (
          <BigButton fluid
                     secondary
                     className={this.getFilterClassName(f)}
                     onClick={handler}
          >{f.label}
          </BigButton>
        )}
        receipt={receipt}>
        <>
          <Modal.Header><h4>{this.getModalHeader()}</h4></Modal.Header>
          <Modal.Content>
            {this.state.loadingEntries
              ? (
                <Segment basic>
                  <Dimmer active inverted>
                    <Loader size={'small'}/>
                  </Dimmer>
                </Segment>
              )
              : this.renderModalContent(readonly)
            }
          </Modal.Content>
        </>
      </PosView>
    </FullScreen>;
  }
  
  private renderModalContent = (readonly: boolean) => {
    if (!this.props.user.hasRole(Roles.StoreManager)) {
      return (
        <Form>
          <Entries disabled={readonly}
                   onChange={this.handleEntriesChange}
                   entries={this.state.entries}/>
        </Form>
      );
    }
    return <div>
      <Form>
        <Grid>
          <Grid.Column width={8}>
            <Entries disabled={readonly}
                     onChange={this.handleEntriesChange}
                     entries={this.state.entries}/>
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Field>
              <label>Stock Initial</label>
              <Form.Input size="mini" disabled value={this.state.activity.initial}/>
            </Form.Field>
            <Form.Field>
              <label>Livraisons</label>
              <Form.Input size="mini" disabled value={this.state.activity.delivered}/>
            </Form.Field>
            <Form.Field>
              <label>Envois</label>
              <Form.Input size="mini" disabled value={this.state.activity.shipped}/>
            </Form.Field>
            <Form.Field>
              <label>Ventes</label>
              <Form.Input size="mini" disabled value={this.state.activity.sales}/>
            </Form.Field>
          </Grid.Column>
        </Grid>
      </Form>
      <PreviousSession
        api={this.props.apiClient}
        sessionId={this.state.inventorySession.previousInventorySessionId}
        item={this.state.inventoryItem}/>
    </div>
  }
  
  private handleEntriesChange = (entry: IShipmentEntry, amount: string) => {
    let parsedAmount: number | undefined;
    if (amount.length > 0) {
      parsedAmount = parseFloat(amount);
    }
    
    // if (isNaN(parsedAmount) || !isFinite(parsedAmount)) {
    //   return;
    // }
    
    const entriesFromState = this.state.entries;
    const index = entriesFromState.findIndex((e: IShipmentEntry) => e.shipmentId === entry.shipmentId);
    
    if (index > -1) {
      const entries: IShipmentEntry[] = [...entriesFromState];
      entries[index] = {...entry, amount: parsedAmount};
      this.setState({entries});
    }
  }
  
  private handleItemSubmit = async (item: IInventoryItem) => {
    const repo = this.props.apiClient.inventoryErrors;
    try {
      await repo.saveInventoryCorrectionsAsync(item.id, this.state.entries);
      
      const inventoryErrors = await repo.queryInventoryErrorsAsync(this.props.user.hasRole(Roles.StoreManager));
      
      if (inventoryErrors.filter(ie => ie.reviewCount === 0).length === 0) {
        if (!this.props.user.hasRole(Roles.StoreManager)) {
          toastService.triggerToast(InventoryErrors.ALL_ERRORS_CORRECTED);
          this.props.history.push('/dashboard');
          return;
        }
      }
      
      this.setState({inventoryErrors, entries: []});
      
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toastService.triggerToast(e.response.data.message, {type: 'error'});
      } else {
        toastService.triggerToast('Something went wrong while saving the correction', {type: 'error'});
      }
    }
  }
  
  private handleItemSelected = async (item: IInventoryItem) => {
    if (item instanceof NullProduct) {
      this.setState({entries: [], inventoryItem: undefined});
      return;
    }
    
    this.setState({
      inventoryItem: item,
      loadingEntries: true,
    });
    
    const eodShipmentEntries = await this.props
      .apiClient
      .inventoryErrors
      .getEodShipmentsAsync(item.id);
    
    this.setState({
      activity: eodShipmentEntries.activity,
      entries: eodShipmentEntries.entries,
      loadingEntries: false
    });
  }
  
  private isReviewed = (inventoryItem?: IInventoryItem) => {
    if (!inventoryItem) {
      return true;
    }
    
    const idx = this.errorIndex(inventoryItem);
    
    
    if (idx > -1 && this.state.inventoryErrors[idx]) {
      return (this.state.inventoryErrors as IInventoryError[])[idx].reviewCount > 0;
    }
    
    return true;
  }
  
  private errorIndex = (inventoryItem: IInventoryItem) => {
    const {inventoryErrors} = this.state;
    return inventoryErrors.findIndex((e: IInventoryError) => e.inventoryItem.id === inventoryItem.id);
  }
  
  private overrideItemStyle = (inventoryItem: IInventoryItem) => {
    const idx = this.errorIndex(inventoryItem);
    
    if (idx > -1) {
      const theError = this.state.inventoryErrors[idx];
      return theError.reviewCount > 0 ? 'inventoryErrors badge success' : 'inventoryErrors';
    }
    
    return '';
  }
  
  private getFilterClassName(f: IPosViewFilter) {
    return this.state.inventoryErrors
      .filter(e => e.inventoryItem.tags.indexOf(f.value) > -1)
      .filter(e => e.reviewCount === 0)
      .length > 0
      ? 'badge alert'
      : '';
  }
  
  private getModalHeader() {
    const {inventoryErrors, inventoryItem} = this.state;
    if (inventoryItem) {
      const errorIndex = this.errorIndex(inventoryItem);
      if (errorIndex > -1) {
        const error = inventoryErrors[errorIndex];
        return inventoryItem.name + '  ' + error.amount;
      }
      return inventoryItem.name;
    }
    return '';
  }
}
