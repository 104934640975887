import * as React from 'react';
import { ChangeEvent } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { RouteComponentProps } from 'react-router';
import { Button, Form, Modal } from 'semantic-ui-react';
import { IInventoryItem } from '../../../../../../../models/IInventoryItem';
import { IApiClient } from '../../../../../../../shared/Api/IApiClient';
import { BottomRight, FullScreen, TopMenu } from '../../../../../../../shared/components';
import { PosFilters } from '../../../../../../../shared/components/POS/PosFilters';
import { BigButton, PosView } from '../../../../../../../shared/components/POS/PosView';
import { ICurrentUserContext } from '../../../../../../../shared/ICurrentUserContext';
import { Logger } from '../../../../../../../shared/Logger';
import { toastService } from '../../../../../../../shared/toastService';
import { IShipment } from '../../../shared/models/IShipment';
import { NullProduct } from '../../../shared/models/NullProduct';
import { NullShipment } from '../../../shared/models/NullShipment';
import { ShipmentActions } from './ShipmentActions';

// region Typings
export interface IShipmentProps {
  apiClient: IApiClient,
  userContext: ICurrentUserContext,
}

export interface IShipmentState {
  products: IInventoryItem[],
  shipment: IShipment,
  error: string,
  tempValue: string,
  currentProduct: IInventoryItem,
  showScheduleModal: boolean,
  scheduleDate: Date,
}

// endregion
export class Shipment extends React.Component<IShipmentProps & RouteComponentProps, IShipmentState> {
  private static filters = PosFilters;

  private ref = React.createRef();

  constructor(props: IShipmentProps & RouteComponentProps, context: any) {
    super(props, context);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    this.state = {
      currentProduct: new NullProduct(),
      error: '',
      products: [],
      scheduleDate: tomorrow,
      shipment: new NullShipment(),
      showScheduleModal: false,
      tempValue: '',
    };
  }

  public async componentDidMount() {
    const api = this.props.apiClient;
    const uuid = this.props.match.params['uuid'];
    let shipment: IShipment;
    let products: IInventoryItem[];

    try {
      products = await api.inventoryItems.queryInventoryItemsAsync();
      this.setState({products});
    } catch (e) {
      Logger.error('Could not fetch products', e);
    }

    try {
      shipment = await api.shipments.getShipmentAsync(uuid);
      this.setState({shipment});
    } catch (e) {
      Logger.error('Could not fetch shipment', e);
      this.props.history.push('/shipments');
    }
  }

  public render() {
    function filter(product: IInventoryItem, value: string) {
      return product.tags.indexOf(value) > -1;
    }

    const shipmentUuid = this.state.shipment.uuid;

    const receipt = {
      items: this.state.shipment.items.map(i => ({
        amount: i.amount,
        itemId: i.id.toString(),
        itemName: i.product.name
      }))
    };

    const name = (this.state.currentProduct instanceof NullProduct)
      ? ''
      : this.state.currentProduct.name;

    return (
      <FullScreen>
        <TopMenu {...this.props}>Shipment n°{shipmentUuid}</TopMenu>
        <PosView
          filterItems={Shipment.filters}
          filterFn={filter}
          items={this.state.products}
          onDeleteItem={this.onDeleteItem}
          onItemSelected={this.handleItemSelected}
          onItemSubmit={this.handleItemChanged}
          readonly={this.state.shipment.isReadOnly}
          renderItem={(p, handler) => <BigButton onClick={handler} fluid>{p.name}</BigButton>}
          renderFilter={(f, handler) => (
            <BigButton fluid secondary onClick={handler}>
              {f.label}
            </BigButton>
          )}
          receipt={receipt}>
          <>
            <Modal.Header>{name}</Modal.Header>
            <Modal.Content>
              <Form id="theForm">
                <Form.Field>
                  <label>Quantité</label>
                  <input
                    ref={this.ref as any}
                    type="number"
                    disabled={this.state.shipment.isReadOnly}
                    value={this.state.tempValue}
                    onChange={this.setTempValue}/>
                </Form.Field>
              </Form>
            </Modal.Content>
          </>
        </PosView>
        <BottomRight>
          <ShipmentActions
            id="toggleShipmentActions"
            shipment={this.state.shipment}
            user={this.props.userContext.user}
            onDeleteShipment={this.handleDeleteShipment}
            onSubmit={this.handleSubmit}
            onScheduleShipment={this.handleScheduleShipment}
            onSubmitDraft={this.handleSubmitDraft}
          />
        </BottomRight>
        <Modal open={this.state.showScheduleModal}>
          <Modal.Header>Choisir la date de l"envoi des marchandises</Modal.Header>
          <Modal.Content>
            <DayPicker
              onDayClick={this.handleChooseShipmentDate}
              selectedDays={this.state.scheduleDate}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleScheduleShipment}>terminer</Button>
          </Modal.Actions>
        </Modal>
      </FullScreen>
    );
  }

  private handleSubmitDraft = () => {
    this.props.history.push('/shipments');
  }

  private handleItemSelected = (p: IInventoryItem, v: number) => {
    this.setState({tempValue: v.toString(), currentProduct: p});
    setTimeout(() => {
      if (this.ref.current !== null) {
        (this.ref.current as HTMLInputElement).focus();
      }
    }, 200);
  };

  private setTempValue = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    this.setState({tempValue: e.target.value});
  };

  private handleItemChanged = async (p: IInventoryItem) => {
    try {
      const amount = parseFloat(this.state.tempValue);
      if (isNaN(amount)) {
        return;
      }
      const theUpdatedShipment = await this.props.apiClient.shipments.saveShipmentItemAsync(
        this.state.shipment.uuid,
        p.id,
        amount);
      this.setState({shipment: theUpdatedShipment, tempValue: ''});
    } catch (e) {
      this.setState({tempValue: ''});
      Logger.error('There was a problem saving the item');
    }
  };

  private onDeleteItem = async (itemId: string) => {
    const inventoryItem = this.state.shipment.items.find(i => i.id.toString() === itemId);
    if (inventoryItem) {
      const theUpdatedShipment = await this.props.apiClient.shipments.saveShipmentItemAsync(
        this.state.shipment.uuid,
        inventoryItem.product.id,
        0);
      this.setState({shipment: theUpdatedShipment});
    }
  };

  private handleSubmit = async () => {
    try {
      await this.props.apiClient.shipments.closeShipment(this.state.shipment);
      this.props.history.push('/shipments');
    } catch (e) {
      Logger.error(e);
    }
  }

  private handleDeleteShipment = async () => {
    try {
      await this.props.apiClient.shipments.deleteShipment(this.state.shipment);
      toastService.triggerToast('Shipment deleted!');
      this.props.history.push('/shipments');
    } catch (e) {
      toastService.triggerToast("Le document n'a pas pu être annulé", {type: 'error'});
      Logger.error(e);
    }
  }

  private handleScheduleShipment = async () => {
    if (!this.state.showScheduleModal) {
      this.setState({showScheduleModal: true});
    } else {
      await this.props.apiClient.shipments.scheduleShipment(this.state.shipment, this.state.scheduleDate);
      this.props.history.push('/shipments');
    }
  }

  private handleChooseShipmentDate = (day: Date) => {
    this.setState({scheduleDate: day});
  }
}
