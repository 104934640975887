import * as React from 'react';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Confirm, Dropdown, Transition } from 'semantic-ui-react';
import { Roles } from '../../../../../../../shared/Roles';
import { IUser } from '../../../../../../../shared/User/IUser';
import { IShipment } from '../../../shared/models/IShipment';

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  onDeleteShipment: () => void,
  onSubmit: () => void,
  onSubmitDraft: () => void,
  onScheduleShipment: () => void,
  shipment: IShipment,
  user: IUser,
}

export class ShipmentActions extends React.Component<IProps> {
  public state = {
    showPrompt: false
  }

  public render() {
    return (
      <div>
        <Confirm onCancel={this.closePrompt} open={this.state.showPrompt} onConfirm={this.confirm}/>
        <Transition visible={!this.props.shipment.isReadOnly} animation="zoom">
          <Dropdown
            id={this.props.id}
            disabled={this.props.shipment.isReadOnly}
            floating
            button
            upward
            icon="arrow right"
            className="icon pink circular"
          >
            <Dropdown.Menu direction="left">
              <Dropdown.Item onClick={this.props.onSubmitDraft}>Garder Brouillon</Dropdown.Item>
              {!this.props.shipment.isEndofday && (
                <Dropdown.Item onClick={this.props.onSubmit}>Clôturer le document</Dropdown.Item>
              )}
              {!this.props.shipment.isEndofday && this.props.user.hasRole(Roles.StoreManager) && (
                <Dropdown.Item onClick={this.props.onScheduleShipment}>Planifier l'envoi</Dropdown.Item>
              )}
              {this.props.user.hasRole(Roles.StoreManager) && (
                <Dropdown.Item onClick={this.prompt}>Annuler l'envoi</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Transition>
      </div>
    );
  }

  private confirm = () => {
    this.setState({showPrompt: false}, () => {
      this.props.onDeleteShipment();
    });
  }

  private closePrompt = () => {
    this.setState({showPrompt: false});
  }

  private prompt = () => {
    this.setState({showPrompt: true});
  }
}
