import { AxiosInstance } from "axios";
import { EodInventoryClientBase } from "./EodInventoryClientBase";
import { IApiClient } from "./IApiClient";
import { IEodInventoryClient } from "./IEodInventoryClient";
import { IInventoryErrorsRepository } from "./IInventoryErrorsRepository";
import { IInventoryItemsRepository } from "./IInventoryItemsRepository";
import { IInventorySessionsRepository } from "./IInventorySessionsRepository";
import { IMarchandisesRepository } from "./IMarchandisesRepository";
import { InventoryItemsRepository } from "./InventoryItemsRepository";
import { InventorySessionsRepository } from "./InventorySessionsRepository";
import { IProductRepository } from "./IProductRepository";
import { IShipmentsRepository } from "./IShipmentsRepository";
import { MarchandisesRepository } from "./MarchandisesRepository";
import { ProductRepository } from "./ProductRepository";
import { PulpWarehouse } from "./PulpWarehouse";
import { ShipmentsRepository } from "./ShipmentsRepository";
import { UserInventoryErrorsRepository } from "./UserInventoryErrorsRepository";

export class ApiClient implements IApiClient {
  get inventoryErrors(): IInventoryErrorsRepository {
    return this._inventoryErrors;
  }

  set inventoryErrors(value: IInventoryErrorsRepository) {
    this._inventoryErrors = value;
  }

  set location(value: PulpWarehouse) {
    this.shipments.location = value;
    this.inventorySessions.location = value;
    this.eodInventory.location = value;
    this.inventoryErrors.location = value;
    this.marchandises.location = value;
  }

  public readonly inventoryItems: IInventoryItemsRepository;
  public readonly shipments: IShipmentsRepository;
  public readonly inventorySessions: IInventorySessionsRepository;
  public readonly eodInventory: IEodInventoryClient;
  public readonly products: IProductRepository;
  public readonly marchandises: IMarchandisesRepository;

  private _inventoryErrors: IInventoryErrorsRepository;

  constructor(private _http: AxiosInstance) {
    this.shipments = new ShipmentsRepository(this._http);
    this.inventorySessions = new InventorySessionsRepository(this._http);
    this.inventoryItems = new InventoryItemsRepository(this._http);
    this._inventoryErrors = new UserInventoryErrorsRepository(this._http);
    this.eodInventory = new EodInventoryClientBase(this._http);
    this.products = new ProductRepository(this._http);
    this.marchandises = new MarchandisesRepository(this._http);
  }
}
