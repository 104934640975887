import {IProductRatio} from './IProductRatio';

export interface IInventoryItem {
  id: number;
  name: string;
  tags: string[];
  productRatios: IProductRatio[];
  active?: boolean;
}

export class InventoryItem implements IInventoryItem {
  public id = 0;
  public name = '';
  public tags = [];
  public productRatios = [];
  public active = true;
}