import * as React from 'react';
import { ReactNode } from 'react';
import { ICurrentUserContext } from '../../../../../shared/ICurrentUserContext';
import { ImpersonateModal } from './ImpersonateModal';

export function ImpersonateGuard(props: { children: ReactNode, userContext: ICurrentUserContext }) {
  const {user, setWorkingFrom} = props.userContext;
  return (
    <div>
      {user.shouldImpersonate() && <ImpersonateModal user={user} onSelected={setWorkingFrom}/>}
      {!user.shouldImpersonate() && props.children}
    </div>
  );
}