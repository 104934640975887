// endregion
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Confirm, Dropdown, Grid, Transition } from 'semantic-ui-react';
import { IApiClient } from '../../../../../shared/Api/IApiClient';
import { PulpWarehouse } from '../../../../../shared/Api/PulpWarehouse';
import { BottomRight, TopMenu } from '../../../../../shared/components';
import { Layout } from '../../../../../shared/components/Layout';
import { Padded } from '../../../../../shared/components/POS/PosView';
import { ICurrentUserContext } from '../../../../../shared/ICurrentUserContext';
import { EodDocumentType } from '../shared/models/EndofdayType';
import { IShipment } from '../shared/models/IShipment';
import { ImpersonateGuard } from './ImpersonateGuard';
import { ListOfArrivals } from './ListOfArrivals';
import { ListOfShipments } from './ListOfShipments';
import { NewEndofdayModal } from './NewEndofdayModal';
import { NewShipmentModal } from './NewShipmentModal';

// region Typings
interface IShipmentsProps {
  apiClient: IApiClient,
  userContext: ICurrentUserContext,
}

interface IShipmentsState {
  arrivals: IShipment[],
  destination?: PulpWarehouse,
  loading: boolean,
  openEndofdayModal: boolean,
  origin: PulpWarehouse,
  outputModal: boolean,
  shipments: IShipment[],
  promptStartEndOfDay: boolean,
}

// endregion

export class Shipments extends React.Component<IShipmentsProps & RouteComponentProps, IShipmentsState> {
  constructor(props: IShipmentsProps & RouteComponentProps) {
    super(props);
    this.state = {
      arrivals: [],
      loading: false,
      openEndofdayModal: false,
      origin: PulpWarehouse.Impé,
      outputModal: false,
      promptStartEndOfDay: false,
      shipments: [],
    };
  }

  public async componentDidMount() {
    const {user} = this.props.userContext;
    const origin = this.getOptionsForOriginSelector()[0].value;
    if (user.workingFrom) {
      await this.loadShipmentsAndSetState(origin);
    }
  }

  public async componentDidUpdate(prevProps: IShipmentsProps) {
    const {user} = this.props.userContext;
    if (user.workingFrom !== prevProps.userContext.user.workingFrom) {
      const {shipments, arrivals} = await this.loadShipments();
      this.setState({shipments, arrivals});
    }
  }

  public render() {
    const {user} = this.props.userContext;

    return (
      <ImpersonateGuard userContext={this.props.userContext}>
        <Layout
          nav={(
            <TopMenu {...this.props}>
              Shipments
            </TopMenu>
          )}
          body={(
            <Padded>
              <Grid centered columns={2}>
                <Grid.Column>
                  <ListOfArrivals
                    onReceive={this.handleReceiveShipment}
                    items={this.state.arrivals}
                    loading={this.state.loading}
                  />
                  <br/>
                  <ListOfShipments
                    user={this.props.userContext.user}
                    shipments={this.state.shipments}
                    loading={this.state.loading}
                  />
                </Grid.Column>
              </Grid>
            </Padded>
          )}/>
        <Transition visible={!this.state.loading} animation={'scale'}>
          <BottomRight>
            <Dropdown id="toggleShipmentsActions" floating button upward icon="plus" className="icon pink circular">
              <Dropdown.Menu direction="left">
                <Dropdown.Item
                  disabled={this.isEndofdayProcessHasStarted()}
                  onClick={this.toggleOutputModal}
                >Envoyer des marchandises
                </Dropdown.Item>

                <Dropdown.Item
                  disabled={this.isEndofdayProcessHasStarted()}
                  onClick={this.receiveFromAtelier}
                >Réceptionner des marchandises de l'atelier
                </Dropdown.Item>

                <Dropdown.Item
                  disabled={this.isEndofdayProcessHasStarted() || !this.hasShipments()}
                  onClick={this.promptStartEndOfDay}
                >Démarrer clôture
                </Dropdown.Item>

                <Dropdown.Item
                  disabled={!this.isEndofdayProcessHasStarted()}
                  onClick={this.handleFinishEndofday}
                >Terminer clôture
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </BottomRight>
        </Transition>
        <Confirm
          content={'Démarrer clôture: are you sure?'}
          open={this.state.promptStartEndOfDay}
          onCancel={this.closePrompt}
          onConfirm={this.handleOpenEndofday}
        />
        <NewShipmentModal
          id="newShipmentModal"
          open={this.state.outputModal}
          onClose={this.toggleOutputModal}
          optionsForOriginSelector={this.getOptionsForOriginSelector()}
          selected={this.state.origin}
          currentUser={user}
          onSelected={this.whenOriginChanges}
          onSelected1={this.whenDestinationChanges}
          b={this.isValid()}
          onSubmit={this.handleSubmit}/>
        <NewEndofdayModal
          open={this.state.openEndofdayModal}
          onClose={this.handleCloseEndofdayModal}
          onCreate={this.handleCreateNewEndofday}
        />

      </ImpersonateGuard>
    );
  }

  private closePrompt = () => {
    this.setState({promptStartEndOfDay: false});
  }

  private handleFinishEndofday = () => {
    this.props.apiClient.eodInventory
      .close()
      .then(() => this.props.history.push('/dashboard'));
  }

  private async loadShipmentsAndSetState(origin: PulpWarehouse) {
    this.setState({loading: true});
    const {shipments, arrivals} = await this.loadShipments();
    this.setState({shipments, origin, arrivals, loading: false, promptStartEndOfDay: false});
  }

  private hasShipments = () => {
    return this.state.shipments.filter(s => !s.isEndofday).length > 2;
  }

  private isEndofdayProcessHasStarted = () => {
    return this.state.shipments.filter(s => s.isEndofday).length > 0;
  }

  private handleCreateNewEndofday = async (type: EodDocumentType) => {
    const shipment = await this.props.apiClient.shipments.createEndofdayShipmentAsync(type);
    this.props.history.push(this.props.match.path + '/' + shipment.uuid);
  }

  private handleCloseEndofdayModal = () => {
    this.setState({openEndofdayModal: false});
  }

  private promptStartEndOfDay = () => {
    this.setState({promptStartEndOfDay: true});
  }

  private handleOpenEndofday = () => {
    this.setState({promptStartEndOfDay: false}, () => {
      this.props.apiClient.eodInventory
        .start()
        .then(() => {
          this.loadShipmentsAndSetState(this.props.userContext.user.workingFrom);
        });
    })
  }

  private receiveFromAtelier = async () => {
    const shipment = await this.props.apiClient.shipments.receiveFromAtelier();

    this.props.history.push(this.props.match.path + '/' + shipment.uuid);

  }

  private handleReceiveShipment = async (uuid: string) => {
    await this.props.apiClient.shipments.receiveShipment(uuid);
    const {shipments, arrivals} = await this.loadShipments();
    this.setState({shipments, arrivals});
  }

  private toggleOutputModal = () => {
    this.setState({outputModal: !this.state.outputModal});
  };

  private whenOriginChanges = (value: PulpWarehouse) => {
    this.setState({origin: value});
  };

  private getOptionsForOriginSelector() {
    const {user} = this.props.userContext;
    return user.canCreateShipmentFrom().map((location: PulpWarehouse) => (
      {text: PulpWarehouse[location], value: PulpWarehouse[location]}
    ));
  }

  private whenDestinationChanges = (value: PulpWarehouse) => {
    this.setState({destination: value});
  };

  private isValid(): boolean {
    if (this.state.origin && this.state.destination) {
      return true;
    }
    return false;
  }

  private handleSubmit = async () => {
    const {origin, destination} = this.state;

    const shipment = await this.props.apiClient.shipments.createShipmentAsync(
      origin as PulpWarehouse,
      destination as PulpWarehouse
    );

    this.props.history.push(this.props.match.path + '/' + shipment.uuid);
  };

  private loadShipments(): Promise<{ shipments: IShipment[], arrivals: IShipment[] }> {
    return this.props.apiClient.shipments.getShipmentsAsync();
  }
}
