import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { InjectShipmentsDependencies } from './components/InjectShipmentsDependencies';
import { ShipmentRoute } from './screens/Shipment';

export function ShipmentsRoute(props: RouteComponentProps) {
  return (
    <Switch>
      <Route path={props.match.path + '/:uuid'} component={ShipmentRoute}/>
      <Route path={props.match.path} component={InjectShipmentsDependencies}/>
    </Switch>
  );
}

