import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, List } from 'semantic-ui-react';
import { PulpWarehouse } from '../../../../../shared/Api/PulpWarehouse';
import { IShipment } from '../shared/models/IShipment';

export function EndofdayShipment(props: { shipment: IShipment }) {
  const shipment = props.shipment;

  let header = 'Transfert';
  let description = `transfert vers ${shipment.destination}`;

  if (props.shipment.destination === props.shipment.origin) {
    header = 'Inventaire';
    description = 'inventaire de fin de journée: ' + props.shipment.description;
  }

  if (props.shipment.destination === PulpWarehouse.Trash) {
    header = 'Mère Thérèsa';
    description = 'inventaire des produits périmés';
  }

  return <List.Item key={shipment.uuid}>
    <List.Content floated="right">
      <Button as={Link} to={`/shipments/${shipment.uuid}`} size="mini">
        {shipment.isReadOnly ? 'Voir' : 'Envoyer'}
      </Button>
    </List.Content>
    <List.Icon name="key"/>
    <List.Content>
      <List.Header>Fin de journée: {header}</List.Header>
      <List.Description>
        <small>{description}</small>
      </List.Description>
    </List.Content>
  </List.Item>;
}