import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router';
import { ApiContext } from '../../../../shared/Api/ApiContext';
import { CurrentUserContext } from '../../../../shared/CurrentUserContext';
import { ImpersonateGuard } from '../Shipments/components/ImpersonateGuard';
import { InventoryErrors } from './components/InventoryErrors';

export function InventoryErrorsRoute(props: RouteComponentProps) {
  return <Route
    path="/"
    render={(routeProps) => (
      <CurrentUserContext.Consumer>
        {(userCtx) => (
          <ApiContext.Consumer>
            {apiClient => (
              <ImpersonateGuard userContext={userCtx}>
                <InventoryErrors user={userCtx.user} apiClient={apiClient} {...routeProps} />
              </ImpersonateGuard>
            )}
          </ApiContext.Consumer>
        )}
      </CurrentUserContext.Consumer>
    )}
  />
}