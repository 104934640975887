import { PulpWarehouse } from '../../../../../../shared/Api/PulpWarehouse';
import { IShipment, ShipmentStatuses } from './IShipment';
import { IShipmentItem } from './IShipmentItem';

export class NullShipment implements IShipment {
  public id: number = 0;
  public uuid: string = '';
  public description = '';
  public destination: PulpWarehouse = PulpWarehouse.Impé;
  public origin: PulpWarehouse = PulpWarehouse.Impé;
  public createdAt: Date = new Date();
  public createdBy: string = '';
  public isReadOnly: boolean = true;
  public items: IShipmentItem[] = [];
  public lastUpdateAt: Date = new Date();
  public lastUpdatedBy: string = '';
  public status: string = '';
  public workday: Date = new Date();
  public nextStatus = ShipmentStatuses.New;
  public isEndofday = false;
}
