import { IInventoryError } from '../../models/IInventoryError';
import { IShipmentEntry } from './IEodShipmentsByProduct';
import { AInventoryErrorsRepository } from './InventoryErrorsRepository';

export class AdminInventoryErrorsRepository extends AInventoryErrorsRepository {
  public queryInventoryErrorsAsync(force?: boolean): Promise<IInventoryError[]> {
    if (!this._location) {
      throw new Error('Please set the location on the ApiClient before using it.');
    }
    
    const url = `v1.0/locations/${this._location}/inventory-errors`;
    
    if (!force) {
      return this._http
        .get(url, {params: {force}})
        .then(response => response.data);
    } else {
      return this._http
        .put(url, {params: {}})
        .then(response => response.data);
    }
  }
  
  public saveInventoryCorrectionsAsync(itemId: number, entries: IShipmentEntry[]): Promise<IInventoryError[]> {
    const shipmentItemResources = entries.map(entry => ({
      amount: entry.amount,
      productId: itemId,
      shipmentId: entry.shipmentId
    }));
    const url = `v1.0/locations/${this._location}/inventory-errors/corrections/admin`;
    return this._http.post(url, shipmentItemResources).then(response => response.data);
  }
}
